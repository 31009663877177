<template>
  <!-- <div> -->
    <div class="paytip">
        <div class="paytip-arrow"></div>
        <div class="paytip-txt">订单尚未完成支付</div>
    </div>
  <!-- </div> -->
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>

.paytip{
  position: absolute;
  display: inline-block;
}
.paytip-arrow{
  position: absolute;
  border-style: solid;

  border-width: 5px 5px 5px 0;
  border-right-color: #000;
  border-top-color: transparent!important;
  border-left-color: transparent!important;
  border-bottom-color: transparent!important;
  margin-left: -5px;
  margin-top: 8px;
  border-color: #ffa625;
}
.paytip-txt{  
  color: #fff;
  font-size: 12px;
  // padding: 5px 10px;
  line-height: 30px;
  padding: 0px 10px;
  background: #ffa625;
  border-radius: 3px;
}

</style>
