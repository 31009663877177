<template>
  <a-modal
    :visible.sync="show"
    title="订单支付"
    destroyOnClose
    width="550px"
    @cancel="handlerCancel"
    :footer="null"
  >
  <div class="justify-center">
    <div style="width:100%;">
      <a-row>
        <a-col :span="24">
          <a-form-model-item :labelCol="{span:5}" :wrapperCol="{span:19}" label="订单总额" style="margin-bottom:0;">
            ￥ {{totalAmount}} 元
          </a-form-model-item>
        </a-col>
      </a-row>
      
      <!-- 总部客服：科技公司 -->
      <template v-if="!isCityManage">
        <a-row>
          <a-col :span="24">
            <a-form-model-item :labelCol="{span:5}" :wrapperCol="{span:19}" label="支付方式">
              <a-radio-group v-model="payType">
                <!-- <a-radio :value="2">微信</a-radio>
                <a-radio :value="3">支付宝</a-radio> -->
                <!-- <a-radio :value="20">已收款（科技公司400）</a-radio> -->
                <!-- <a-radio :value="10" :disabled="orderType!=2">货到付款</a-radio> -->
                <a-radio :value="10">货到付款</a-radio>
              </a-radio-group>
            </a-form-model-item>
          </a-col>
        </a-row>
      </template>
      <!-- 城市客服 -->
      <template v-if="isCityManage">
        <a-row>
          <a-col :span="24">
            <a-form-model-item :labelCol="{span:5}" :wrapperCol="{span:19}" label="支付方式">
              <a-radio-group v-model="payType">
                <a-radio :value="20">已收款</a-radio>
                <a-radio :value="10" :disabled="orderType!=2">货到付款</a-radio>
              </a-radio-group>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row v-if="payType==20" class="mb-2">
          <a-col :span="24">
            <a-form-model-item :labelCol="{span:5}" :wrapperCol="{span:19}" label="收款财务账户" style="margin-bottom:0;">
              <a-select placeholder="请选择收款账户" style="width:100%;"
                        v-model="financial_account_id">
                <a-select-option v-for="(item, index) in accountList"
                                 :key="index"
                                 :value="item.id">{{ item.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
      </template>
        

      <!-- 其他支付 -->
      <template v-if="payType==20" class="mt-2">
        <a-row class="mt-2">
          <a-col :span="24">
            <a-form-model-item :labelCol="{span:5}" :wrapperCol="{span:19}" 
              label="付款凭证" class="upload-class" style="margin-bottom:0;">
              <uploadImage type="30" multiple :value.sync="img_files" @getList="(list)=>{img_files = list}" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row class="mb-2">
          <a-col :span="24">
            <a-form-model-item :labelCol="{span:5}" :wrapperCol="{span:19}" label="支付流水号" style="margin-bottom:0;">
              <a-input v-model="artificial_pay_flow_no"></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row class="mb-2">
          <a-col :span="24">
            <a-form-model-item :labelCol="{span:5}" :wrapperCol="{span:19}" label="备注" style="margin-bottom:0;">
              <a-input type="textareas" v-model="remarks"></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
      </template>
      <!-- 货到付款 -->
      <div v-if="payType==10" class="mt-5">
        <a-row class="mb-2">
          <a-col :span="24">
            <a-form-model-item :labelCol="{span:5}" :wrapperCol="{span:19}" 
              label="预期方式">
              <a-select v-model="subCODType" style="width:100%;">
                <a-select-option
                  v-for="item in subCODTypeOptions"
                  :key="item.id"
                  :value="item.id"
                >{{item.name}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
      </div>

      <!-- 线上支付 -->
      <!-- <div id="qrcode" ref="qrcode"></div> -->

      <div class="mt-5 mb-5 flex justify-center">
        <!-- <a-button type>取消</a-button> -->
        <a-button type="primary" @click="handlerPay">确定</a-button>
      </div>
    </div>
  </div>
    
  </a-modal>
</template>

<script>
import { subCODPayTypeList } from '@/utils/type'
import { setOrderPayType } from '@/api/order/create'
// import QRCode from 'qrcodejs2'
import uploadImage from "@/components/upload-image"
import { isCityManage } from '@/utils/authData.js'
import {
  getList as getAccountList,
} from "@/api/system/account"

export default {
  components: { uploadImage },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    orderId: {
      type: String,
      default: '',
    },
    orderType: {
      type: Number,
      default: 2,
    },
    orderCityAdcode: {
      type: [String, Number],
      default: 2,
    },
    totalAmount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isCityManage: isCityManage(),

      payType: 0,
      financial_account_id: undefined,
      accountList: [],

      artificial_pay_flow_no: "",
      remarks: "",
      img_files: [],
      qrcode: null,

      subCODType: 0,
      subCODTypeOptions: [{ 
        id: 0, 
        name: "无" 
      }].concat(subCODPayTypeList),
    }
  },
  async mounted() {
    if(this.isCityManage && this.orderCityAdcode){
      const { code, data } = await getAccountList({
        belong_outlets_city_adcode: this.orderCityAdcode,
        is_city: 2
      })
      if (code == 0) {
        this.accountList = data.list || []
      }
    }
  },
  methods: {
    // qrcodeScan() {//生成二维码
    //   this.$nextTick(() => {
    //     const qrdiv = document.getElementById('qrcode')
    //     this.qrcode = new QRCode(qrdiv, {
    //       width: 200,  // 二维码宽度 
    //       height: 200, // 二维码高度
    //       text: 'https://mp.csdn.net'
    //     })
    //   })
    // },
    handlerPay() {
      if(!this.payType){
        this.$message.info('请选择支付方式')
        return
      }
      
      if(this.isCityManage){ // 城市 必选财务账户
        if(this.payType == 20){
          if(!this.financial_account_id){
            this.$message.info('请选择收款账户')
            return
          }
        }else{
          this.financial_account_id = undefined
        }
      }else{ // 总部 科技公司
        this.financial_account_id = -1
      }

      let params = {
        order_no: this.orderId,
        pay_type: this.payType,
        financial_account_id: this.financial_account_id || "",
      }
      if(this.payType == 10){
        params.pay_subtype = this.subCODType
      }else if(this.payType == 20){
        Object.assign(params, {
          artificial_pay_flow_no: this.artificial_pay_flow_no,
          remarks:  this.remarks,
          attached_image_paths: this.img_files.join(",")
        })
      }

      // 提交
      let _this = this
      if(this.payType == "20"){ // 其他收款
        this.$confirm({
          title: "确认已收款"+this.totalAmount+"元？",
          okText: "我确认已收款",
          cancelText: "取消",
          onOk() {
            setOrderPayType(params).then(res=>{
              if(res.code == "0"){
                _this.$message.success('操作成功')
                _this.$emit('paystatus')
                // _this.$emit("update:show", false)
              }
            })
          }
        })
      }else{ // 货到付款（目前只分 其他和货到付款）
        setOrderPayType(params).then(res=>{
          if(res.code == "0"){
            _this.$message.success('操作成功')
            _this.$emit('paystatus')
            // _this.$emit("update:show", false)
          }
        })
      }
    },

    handlerCancel(){
      this.$emit('update:show', false)
    }
  }
}
</script>

<style>
</style>