<template>
  <a-modal :visible="show"
           v-bind="$attrs"
           title="发放优惠券"
           :afterClose="remove"
           width="550px"
           @cancel="remove"
           @ok="handleOk">
    <a-form-model ref="tempRef"
                  :labelCol="{ span: 4 }"
                  :wrapperCol="{ span: 20 }"
                  :model="addFrom">
      <a-form-model-item prop="coupon_id"
                         label="优惠券">
        <a-select show-search
                  placeholder="请输入优惠券名称"
                  :default-active-first-option="false"
                  :show-arrow="false"
                  :filter-option="false"
                  :not-found-content="null"
                  @search="initConponList"
                  v-model="addFrom.coupon_id">
          <a-select-option v-for="(item, index) in conponList"
                           :key="index"
                           :value="item.coupon_id">{{item.coupon_name}}
                              <span class="coupon-price">满{{item.least_cost}}减{{item.reduce_cost}}</span>
                              <span class="coupon-price">{{item.end_time}}前</span>
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item prop="type"
                         label="发放理由">
        <a-select v-model="addFrom.type"
                  placeholder="请选择发放理由">
          <a-select-option value="1">协商补偿</a-select-option>
          <a-select-option value="2">其他</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item prop="remarks"
                         label="备注信息">
        <a-input allowClear
                 type="textarea"
                 v-model="addFrom.remarks"
                 placeholder="请输入备注信息"></a-input>
      </a-form-model-item>
    </a-form-model>
  </a-modal>

</template>

<script>
import { getConponList, grantCoupon } from '@/api/vip.js'

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    orderId: {
      type: [Number,String],
      default: ''
    }
  },
  data () {
    return {
      addFrom: {},
      conponList: []
    }
  },

  methods: {

    initConponList (value) {
      if (value) {
        getConponList({ coupon_name: value }).then(res => {
          this.conponList = res.data.list
        })
      }
    },

    remove () {
      this.$emit('update:show', false)
    },
    handleOk () {
      if(!this.addFrom.coupon_id){
        this.$message.warning('请选择优惠券')
        return
      }
      if(!this.addFrom.type){
        this.$message.warning('请选择理由')
        return
      }
      if(!this.addFrom.remarks){
        this.$message.warning('请填写备注')
        return
      }

      const _this = this
      this.$confirm({
        title: '提示',
        content: '是否确认发放该优惠券?',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk () {
          let params = _this.addFrom
          params.order_no=_this.orderId
          grantCoupon(params).then(res => {
            if (res.code === 0) {
              _this.$message.success('发放成功！')
            }
          })
          _this.$emit('update:show', false)
        },
        onCancel () {
          console.log('Cancel');
        },
      });

    },
  }
}
</script>

<style>
.type-table-list__item-span {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.coupon-price{
  margin-left: 10px;
  color: #ccc;
}
</style>