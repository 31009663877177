<template>
  <a-modal
    :visible.sync="show"
    title="短信发送记录"
    destroyOnClose
    width="1000px"
    @cancel="$emit('update:show', false)"
    :footer="null"
  >
    <base-table class="mb-4"
      :columnsData="columns"
      :customHeight="300"
      :tableData="tableData"
      :getListFunc="initData"
    >
    </base-table>
  </a-modal>
</template>

<script>
import { formatSmsType } from "@/utils/type"
import { getOrderSmsLogList } from "@/api/customer"

export default {
  components: {
    
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    orderId: {
      type: [String, Number],
    },
  },
  data() {
    return {
      columns: [
        // {
        //   title: "ID",
        //   dataIndex: "id",
        //   align: "center",
        //   width: 70,
        // },
        {
          title: "类型",
          dataIndex: "type",
          align: "center",
          width: 100,
          slots: {
            customRender: "type",
          },
          slotsType: "format",
          slotsFunc: (val) => formatSmsType(val),
        },
        {
          title: "发送时间",
          dataIndex: "add_time",
          align: "center",
          width: 130,
        },
        {
          title: "手机号",
          dataIndex: "phone",
          align: "center",
          width: 130,
        },
        {
          title: "短信内容",
          dataIndex: "content",
          align: "center",
          // width: 70,
        },
        // {
        //   title: "发送状态",
        //   dataIndex: "send_status",
        //   align: "center",
        //   width: 80,
        // },
        // {
        //   title: "发送结果",
        //   dataIndex: "send_return_status",
        //   align: "center",
        //   width: 80,
        // },
      ],
      tableData: [],
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    async initData() {
      let res = await getOrderSmsLogList({ order_no: this.orderId })
      if(res.code == 0){
        this.tableData = res.data.list
      }
    },

  },
}
</script>

<style>
.ant-modal-body {
  position: relative;
}
</style>