<template>
  <a-modal :visible.sync="show"
           title="拆分订单"
           destroyOnClose
           width="55%" @ok="handleOk"
           @cancel="$emit('update:show', false)">

    <base-table ref="goodsTable"
                :columnsData="columns"
                rowKey="order_goods_id"
                :tableData="tableData"
                :row-selection="rowSelection">
    </base-table>
    <div class="mt-2">
      选中<span class="text-red-500">{{rowSelection.selectedRowKeys.length}}</span>件商品，
      合计：{{totalPrice}}元
    </div>
  </a-modal>
</template>

<script>
import { formatGoodsType } from '@/utils/type'
import {getSplitOrderGoodsList} from '@/api/customer'
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    orderId: {
      type: [String, Number],
    },
    selectKeys: {
      type: Array,
    },
    handlerOk: {
      type: Function,
    },
  },
  computed:{
    totalPrice(){
      let price = 0
       this.selectRows.map(item=>{
        price = price + parseFloat(item.order_price)
      })
      return  Math.round(price*100)/100
    }
  },
  data () {
    return {
      selectRows: [],
      rowSelection: {
        selectedRowKeys: [],
        onSelect: (record, selected) => {
          if (selected) {
            this.rowSelection.selectedRowKeys.unshift(record.order_goods_id)
            this.selectRows.unshift(record)
          } else {
            this.rowSelection.selectedRowKeys = this.rowSelection.selectedRowKeys.filter(
              (el) => el != record.order_goods_id
            )
            this.selectRows = this.selectRows.filter(el => el.order_goods_id != record.order_goods_id)
          }
        },
        onSelectAll: (selected, selectedRows) => {
          this.selectRows = this.selectedRows
          if(selected){
            this.rowSelection.selectedRowKeys = selectedRows.map(el=>el.order_goods_id)
            this.selectRows = selectedRows
          }else{
            this.rowSelection.selectedRowKeys = []
            this.selectRows = []
          }
        },
        getCheckboxProps: (record) => {
          return {
            props: {
              defaultChecked: this.selectKeys.includes(record.order_goods_id)
            }
          };
        }
      },
      columns: [
        {
          title: "分类",
          dataIndex: "goods_type",
          align: "center",
          width: 90,
          slots: {
            customRender: "type",
          },
          slotsType: "format",
          slotsFunc: (val) => formatGoodsType(val),
        },
        {
          title: "商品名称",
          dataIndex: "goods_name",
          align: "center",
          width: "40%",
        },
        {
          title: "口味",
          dataIndex: "flavor_name",
          align: "center",
          width: "30%",
        },
        {
          title: "尺寸",
          dataIndex: "specification_name",
          align: "center",
          width: "30%",
        },
        {
          title: "数量",
          dataIndex: "number",
          align: "center",
          width: 80,
        },
        {
          title: "网点库存",
          dataIndex: "outlets_stock",
          align: "center",
          width: 80,
        },
      ],
      tableData: [],
      baseList: [],
      total: 0,
    }
  },
  mounted() {
    this.initList()
    this.rowSelection.selectedRowKeys = this.selectKeys
  },
  methods: {
    initList(){
      getSplitOrderGoodsList({order_no: this.orderId}).then(res=>{
        this.tableData = res.data.list
        this.selectRows = this.tableData.filter(el => this.selectKeys.includes(el.order_goods_id))
      })
    },
    handleOk () {
      this.$emit("ok", this.selectRows)
      this.$emit("update:show", false)
    },
  },
}
</script>

<style>
.ant-modal-body {
  position: relative;
}
</style>