import ajax from '@/utils/ajax.js'


/**
 * 获取会员列表
 * @param {*} params 
 * @returns 
 */
export function getVipList(params) {
  return ajax.post('/Member/getList',params)
}

/**
 * 获取会员详情
 * @param {*} params 
 * @returns 
 */
export function getMemberDetail(params) {
  return ajax.post('/Member/detail',params)
}

/**
 * 修改会员信息（目前只改生日）
 * @param {*} params 
 * @returns 
 */
export function updateMember(params) {
  return ajax.post('/Member/update',params)
}

/**
 * 修改会员手机号
 * @param {*} params 
 * @returns 
 */
export function updateMemberPhone(params) {
  return ajax.post('/Member/updatePhone',params)
}

/**
 * 获取会员变更日志
 * @param {*} params 
 * @returns 
 */
export function getVipChangeLog(params) {
  return ajax.post('/Member/vipCardAccountChangeLog',params)
}

/**
 * 获取可发放优惠券列表
 * @param {*} params 
 * @returns 
 */
export function getConponList(params) {
  return ajax.post('/Member/getCouponListGrantCouponUse',params)
}


/**
 * 发放优惠券
 * @param {*} params 
 * @returns 
 */
export function grantCoupon(params) {
  return ajax.post('/Member/grantCoupon',params)
}

/**
 * 获取 用户的积分记录
 * @param {*} params 
 * @returns 
 */
export function getIntegralRecord(params) {
  return ajax.post('/Member/integralRecord',params)
}

/**
 * 给用户加积分
 * @param {*} params 
 * @returns 
 */
export function addIntegral(params) {
  return ajax.post('/Member/addIntegral',params)
}
/**
 * 给用户减积分
 * @param {*} params 
 * @returns 
 */
export function reduceIntegral(params) {
  return ajax.post('/Member/delIntegral',params)
}

/**
 * 注销用户账户
 * @param {*} params 
 * @returns 
 */
export function closeAccount(params) {
  return ajax.post('/Member/closeAccount',params)
}

/**
 * 获取 用户的实体卡
 * @param {*} params 
 * @returns 
 */
export function getStorecardList(params) {
  return ajax.post('/Member/getStoredValueCardList',params)
}

/**
 * 获取 用户的收货地址
 * @param {*} params 
 * @returns 
 */
export function getAddressList(params) {
  return ajax.post('/Member/getAddressList',params)
}


// --------------- 黑名单

/**
 * 查询用户是否黑名单
 * @param {*} params 
 * @returns 
 */
export function getUserBlackInfo(params) {
  return ajax.post('/MemberBlacklist/getInfo',params)
}

/**
 * 加入黑名单
 * @param {*} params 
 * @returns 
 */
export function putInUserBlack(params) {
  return ajax.post('/MemberBlacklist/add',params)
}

/**
 * 解除黑名单
 * @param {*} params 
 * @returns 
 */
export function pushOutUserBlack(params) {
  return ajax.post('/MemberBlacklist/cancel',params)
}

/**
 * 解除黑名单
 * @param {*} params 
 * @returns 
 */
export function updateUserBlackInfo(params) {
  return ajax.post('/MemberBlacklist/update',params)
}
