<template>
  <div>
    <a-modal
      :visible.sync="show"
      :title="title"
      destroyOnClose
      width="800px"
      @ok="handleOk"
      @cancel="$emit('update:show', false)"
    >
      <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
        <!-- 转配送信息 -->
        <div v-if="suborder_type == 2">
          <a-form-item class="mb-2" label="收货人">
            <a-input placeholder="收货人"
              v-model="deliveryForm.delivery_consignee_name" />
          </a-form-item>
          <a-form-item class="mb-2" label="收货人手机号">
            <a-input placeholder="收货人手机号"
              v-model="deliveryForm.delivery_consignee_phone" />
          </a-form-item>
          <a-form-item class="mb-2" label="收货时间">
            <time-picker2 style="flex: 1"
              :duration="goods_time_duration"
              :value.sync="delivery_time_arr" />
          </a-form-item>
          <a-form-item class="mb-2" label="收货地址">
            <a-select placeholder="请输入所在地址"
              show-search
              v-model="delivery_address"
              :default-active-first-option="false"
              :show-arrow="false"
              :filter-option="false"
              :not-found-content="null"
              @search="handleSearch3"
              @change="handleChange3"
            >
              <a-select-option
                v-for="d in addressList3"
                :value="d.id" :key="d.id">{{ 
                  d.title }}<span class="addr-region">{{d.region}}</span>
              </a-select-option>
            </a-select>
            <a-input placeholder="请输入门牌号"
              allowClear
              v-model="delivery_house_number"></a-input>
          </a-form-item>
          <a-form-item class="mb-2" label="配送网点">
            <span>{{ outlets_name }}</span>
            <a-button class="ml-2 p-0" type="link" size="small"
              @click="handlerSelectOutlets">选择</a-button>
          </a-form-item>
          <a-form-item class="mb-2" label="配送费">
            <a-checkbox v-model="isAgreeNoFreight">免费配送（自提转配送 只能免配送费）</a-checkbox>
          </a-form-item>
        </div>
        <!-- 转自提信息 -->
        <div v-if="suborder_type == 1">
          <a-form-item class="mb-2" label="取货人">
            <a-input placeholder="取货人"
              v-model="pickupForm.pick_up_recipient_name" />
          </a-form-item>
          <a-form-item class="mb-2" label="手机号">
            <a-input placeholder="手机号"
              v-model="pickupForm.pick_up_recipient_phone" />
          </a-form-item>
          <a-form-item class="mb-2" label="取货时间">
            <time-picker2 style="flex: 1"
              :duration="goods_time_duration"
              :value.sync="pick_up_goods_time_arr" />
          </a-form-item>
          <a-form-item class="mb-2" label="自提门店">
            <span>{{ outlets_name }}</span>
            <a-button class="ml-2 p-0" type="link" size="small"
              @click="handlerSelectOutlets">选择</a-button>
          </a-form-item>
          <a-form-item v-if="hasFreight" class="mb-2" label="退配送费">
            <template v-if="isCashDelivery">
              <div>* 订单金额会发生变更（自提无配送费）</div>
            </template>
            <template v-else-if="isAutoRefundFreight">
              <a-checkbox v-model="isAgreeAutoRefundFreight">自动原路退配送费￥{{freightAmount}}（转自提需要退配送费）</a-checkbox>
            </template>
            <template v-else>
              <div>转自提 需要退配送费。本订单不支持自动退、需要手动退运费</div>
              <!-- // debug -->
              <!-- <div>这里应该是 填写流水号等</div> -->
            </template>
          </a-form-item>
        </div>
      </a-form>
    </a-modal>

    <select-outlets-modal 
      v-if="isSelectOutlets" :show.sync="isSelectOutlets"
      :orderId="order_no"
      :type="suborder_type"
      @getSplitGoods="handlerOutletNoGoodsSure"
      @ok="handlerSureOutlets"
    />

  </div>
</template>

<script>
import TimePicker2 from "@/components/order/time-picker2.vue";
import SelectOutletsModal from "../../components/select-outlets-modal.vue";
import { getMapPointList, getAdcode } from "@/utils/tMap";
import { searchShopDeliveryArea } from "@/utils/delivery.js"
import { 
  transformSuborderType
} from "@/api/customer"

export default {
  components: {
    TimePicker2,
    SelectOutletsModal,
  },
  props: {
    orderId: {
      type: [String, Number],
      default: "",
    },
    isCashDelivery: {
      type: Boolean,
      default: false,
    },
    freightAmount: {
      type: [String, Number],
      default: "",
    },
    openTime: {
      type: [String, Number],
      default: 0,
    },
    show: {
      type: Boolean,
    },
    outletsOrder: {
      type: Object,
    },
  },
  data() {
    return {
      title: "",

      order_no: "",
      suborder_type: "",

      hasFreight: false,
      isAutoRefundFreight: false,
      isAgreeAutoRefundFreight: false,

      addressList3: [],

      goods_time_duration: 30,

      deliveryForm: {
        delivery_outlets_id: "",
        delivery_time_start: "",
        delivery_time_end: "",
        delivery_consignee_name: "",
        delivery_consignee_phone: "",
        delivery_consignee_address: "",
        delivery_consignee_lng: "",
        delivery_consignee_lat: "",
        delivery_consignee_adcode: "",
      },
      delivery_time_arr: [],
      delivery_address: undefined,
      delivery_house_number: "",
      isAgreeNoFreight: false,

      pickupForm: {
        pick_up_outlets_id: "",
        pick_up_goods_time_start: "",
        pick_up_goods_time_end: "",
        pick_up_recipient_name: "",
        pick_up_recipient_phone: "",
      },
      pick_up_goods_time_arr: [],

      noStockgoodsList: null,
      isOuteletsNoGoods: false,
      isSelectOutlets: false,
      outlets_name: "",
    };
  },

  mounted() {
    this.order_no = this.orderId;

    if(this.outletsOrder.suborder_type == 1){
      this.title = "转配送"
      this.suborder_type = 2

      this.deliveryForm.delivery_consignee_name  = this.outletsOrder.pick_up_info.recipient_name
      this.deliveryForm.delivery_consignee_phone = this.outletsOrder.pick_up_info.recipient_phone
      this.delivery_time_arr        = this.outletsOrder.goods_time
    }
    if(this.outletsOrder.suborder_type == 2){
      this.title = "转自提"
      this.suborder_type = 1

      this.pickupForm.pick_up_recipient_name  = this.outletsOrder.delivery_info.consignee_name
      this.pickupForm.pick_up_recipient_phone = this.outletsOrder.delivery_info.consignee_phone
      this.pick_up_goods_time_arr  = this.outletsOrder.goods_time

      this.hasFreight = parseFloat(this.freightAmount) > 0
      // debug
      this.isAutoRefundFreight = true
    }

    if(this.outletsOrder.goods_time.length == 2){
        const time_start =
          this.outletsOrder.goods_time[0] + " " +
          this.outletsOrder.goods_time[1].split("-")[0]
        const time_end =
          this.outletsOrder.goods_time[0] + " " +
          this.outletsOrder.goods_time[1].split("-")[1]
      this.goods_time_duration = Math.round((new Date(time_end).getTime()-new Date(time_start).getTime())/60/1000)
    }

  },

  methods: {
    // 快递单地址切换的操作
    async handleSearch3(value) {
      // 根据经纬度反查Adcode
      getMapPointList(value).then((res) => {
        if (res.status == 0) {
          this.addressList3 = res.data.map((item) => {
            item.region = item.province + item.city + item.district;
            return item;
          });
        }
      });
    },

    async handleChange3(value) {
      const item = this.addressList3.find((item) => item.id === value)
      this.delivery_address = (item.address || "") + (item.title || "")
      this.deliveryForm.delivery_consignee_lat = item.location.lat
      this.deliveryForm.delivery_consignee_lng = item.location.lng
      // 根据经纬度反查Adcode
      const adcodesRes = await getAdcode(item.location.lat, item.location.lng)

      if (adcodesRes && adcodesRes.status === 0) {
        this.deliveryForm.delivery_consignee_adcode =
          Number(adcodesRes.result.address_reference.town.id + "000") || ""
      } else {
        this.deliveryForm.delivery_consignee_adcode = ""
      }

      // 修改收货地址 --> 重新计算配送网点
      if(this.deliveryForm.delivery_consignee_adcode){
        let area = await searchShopDeliveryArea({
          lat: this.deliveryForm.delivery_consignee_lat,
          lng: this.deliveryForm.delivery_consignee_lng
        }, this.deliveryForm.delivery_consignee_adcode)
        if (area) {
          if(this.deliveryForm.delivery_outlets_id != area.outlets_id){
            this.$message.info("已切换配送网点："+area.outlets_name)
            this.deliveryForm.delivery_outlets_id = area.outlets_id
            this.outlets_name = area.outlets_name
          } 
        } else {
          this.deliveryForm.delivery_outlets_id = ""
          this.outlets_name = ""
          this.$message.warning("地址超出配送范围");
        }
      }
    },
    // 修改网点
    handlerSelectOutlets() {
      this.noStockgoodsList = [];
      this.isOuteletsNoGoods = false;
      this.isSelectOutlets = true;
    },
    // 确认选择网点
    handlerSureOutlets(outlets) {
      if (this.suborder_type == 1) {
        this.pickupForm.pick_up_outlets_id = outlets.outlets_id
      }
      if (this.suborder_type == 2) {
        this.deliveryForm.delivery_outlets_id = outlets.outlets_id
      }
      this.outlets_name = outlets.outlets_name
    },
    // 如果切换的负责网点没有库存，则对勾选的商品进行拆单赋值
    handlerOutletNoGoodsSure(infos) {
      const outlets = infos.outlets
      if (this.suborder_type == 1) {
        this.pickupForm.pick_up_outlets_id = outlets.outlets_id
      }
      if (this.suborder_type == 2) {
        this.deliveryForm.delivery_outlets_id = outlets.outlets_id
      }
      this.outlets_name = outlets.outlets_name;
    },
    async handleOk() {
      let params = {
        open_time: this.openTime,
        suborder_no: this.outletsOrder.suborder_no,
        suborder_type: this.suborder_type
      }

      if (this.suborder_type == 1) {
        if(!this.pickupForm.pick_up_outlets_id){
          this.$message.info('请选择自提门店')
          return
        }
        if(this.hasFreight){
          if(this.isAutoRefundFreight){
            if(!this.isAgreeAutoRefundFreight){
              this.$message.info('转自提必须退配送费')
              return
            }
          }else{
            // 手动退：校验 流水号/凭证等
            // params = Object.assign(params, {
            //   artificial_refund_flow_no: "", // 是 string  人工退款流水号
            //   artificial_refund_remarks: "", //  否 string  人工退款备注
            //   artificial_refund_attached_image_paths: "", // 人工退款附加图片地址 （多个,分割）
            // })
          }
        }
        this.pickupForm.pick_up_goods_time_start =
          this.pick_up_goods_time_arr[0] + " " +
          this.pick_up_goods_time_arr[1].split("-")[0]
        this.pickupForm.pick_up_goods_time_end =
          this.pick_up_goods_time_arr[0] + " " +
          this.pick_up_goods_time_arr[1].split("-")[1]

        params = Object.assign(params, this.pickupForm)
      }
      if (this.suborder_type == 2) {
        if(!this.delivery_address){
          this.$message.info('请选择收货地址')
          return
        }
        if(!this.deliveryForm.delivery_outlets_id){
          this.$message.info('请选择配送网点')
          return
        }
        if(!this.isAgreeNoFreight){
          this.$message.info('只能免配送费')
          return
        }
        
        this.deliveryForm.delivery_consignee_address = (this.delivery_address || "") + this.delivery_house_number
        this.deliveryForm.delivery_time_start =
          this.delivery_time_arr[0] + " " +
          this.delivery_time_arr[1].split("-")[0]
        this.deliveryForm.delivery_time_end =
          this.delivery_time_arr[0] + " " +
          this.delivery_time_arr[1].split("-")[1]

        params = Object.assign(params, this.deliveryForm)
      }

      const { code } = await transformSuborderType(params)
      if(code == 0){
        if(this.suborder_type == 1){
          this.$message.success("已转成：自提订单")
        }
        if(this.suborder_type == 2){
          this.$message.success("已转成：配送订单")
        }
        this.$emit('ok')
        this.$emit('update:show', false)
      }else{
        if(code == "510"){ // 不能自动退
          this.$message.error("转自提失败：不支持自动退配送费")
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.addr-region{
  margin-left: 10px;
  color: #ccc;
}
</style>