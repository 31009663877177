<template>
  <a-modal
    :visible.sync="show"
    title="发送短信"
    destroyOnClose
    width="700px"
    @cancel="handlerCancel"
    :footer="null"
  >
  <div class="justify-center">
    <div style="width:100%;">
      <div>
        <div class="params-item mb-4">
          <span class="text-left">短信类型：</span>
          <a-select style="flex:1;" v-model="type" @change="updateTypeTmp">
            <a-select-option value="">请选择</a-select-option>
            <a-select-option v-for="item in smsConfig"
              :value="item.type" :key="item.type">{{ item.typeName }}</a-select-option>
          </a-select>
        </div>
        <div class="params-item mb-4">
          <span class="text-left">短信内容：</span>
          <a-textarea style="flex:1;" v-model="content"
            :auto-size="{ minRows: 6, maxRows: 8 }"></a-textarea>
        </div>
      </div>
      <a-divider />

      <div>
        <div class="params-item mb-4">
          <span class="text-left">手机号：</span>
          <a-select v-if="phoneList && phoneList.length" 
              class="mr-2" style="flex:1;" 
              v-model="phoneIndex" @change="onChangePhone">
            <a-select-option value="">自定义</a-select-option>
            <a-select-option v-for="(item, index) in phoneList"
              :value="index" :key="index">{{ item.phone }} {{ item.type }}</a-select-option>
          </a-select>
          <a-input style="flex:1;" v-model="phone" />
        </div>
      </div>

      <!-- <div>
        <template v-if="type==1">
          <div class="params-item">
            <span class="text-left">门店名：</span>
            <a-input v-model="smsData.shop_name" @change="updateData" />
          </div>
          <div class="params-item">
            <span class="text-left">门店地址：</span>
            <a-input v-model="smsData.shop_addr" @change="updateData" />
          </div>
          <div class="params-item">
            <span class="text-left">门店电话：</span>
            <a-input v-model="smsData.shop_tel" @change="updateData" />
          </div>
        </template>
      </div> -->

      <!-- <div>
        <p class="sms-tmp" v-html="contentHTml"></p>
      </div> -->
      
      <div class="mt-5 mb-5 flex justify-end">
        <a-button type="primary" @click="handlerSubmit" :loading="isSending">发送</a-button>
      </div>
    </div>
  </div>
    
  </a-modal>
</template>

<script>
import { smsConfig } from "@/utils/type"
import { getOrderSmsTmp, sendOrderSms } from "@/api/customer"

export default {
  components: {  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    orderId: {
      type: [String, Number],
      default: '',
    },
    phoneList: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      smsConfig,

      phoneIndex: "",
      type: "",
      phone: "",
      content: "",

      isSending: false,
    }
  },
  mounted() {

  },
  methods: {

    updateTypeTmp(){
      let smsItem = this.smsConfig.find(el=>el.type == this.type)
      this.content = ""

      if(smsItem){
        if(/{\$/.test(smsItem.content)){
          getOrderSmsTmp({
            order_no: this.orderId,
            type: this.type,
          }).then(res => {
            if (res.code === 0) {
              this.content = res.data.content
            }
          })
        }else{
          this.content = smsItem.content
        }
      }
    },

    onChangePhone(index){
      const item = this.phoneList[index]
      this.phone = item ? item.phone : ""
    },

    // updateData(){
    //   let smsItem = this.smsConfig.find(el=>el.type == this.type)
    //   if(smsItem){
    //     let htmlTmp = smsItem.content
    //     let tmp = smsItem.content
    //     for(let key in this.paramsData){
    //       htmlTmp = htmlTmp.replace("${"+key+"}", '<span class="para">'+this.paramsData[key]+'</span>')
    //       tmp = htmlTmp.replace("${"+key+"}", this.paramsData[key])
    //     }
    //     this.contentHTml = htmlTmp
    //     this.content = tmp
    //   }else{
    //     this.contentHTml = ""
    //     this.content = ""
    //   }
    // },

    async handlerSubmit() {
      this.isSending = true
      const res = await sendOrderSms({
        order_no: this.orderId,
        phone: this.phone,
        type: this.type,
        content: this.content,
      })
      this.isSending = false

      if (res.code === 0) {
        this.$message.success("已发送")
        this.$emit('update:show', false)
      }
    },

    handlerCancel(){
      this.$emit('update:show', false)
    }
  }
}
</script>

<style>
.params-item{
  display: flex;
  align-items: center;
  width: 100%;
}
.params-item .text-left{
  width: 100px;
}
</style>