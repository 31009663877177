<template>
  <a-modal
    :visible.sync="show"
    title="以下商品网点无库存，是否继续分配至该网点？"
    destroyOnClose
    width="50%"
    @ok="handleOk"
    @cancel="$emit('update:show', false)"
  >
    <base-table
      ref="goodsTable"
      :columnsData="columns"
      :customHeight="300"
      rowKey="order_goods_id"
      :tableData="goodsList"
      :row-selection="rowSelection"
    ></base-table>
    <div v-if="canSplit" class="mt-2">
      选中商品自动拆分订单，快递发货
    </div>
  </a-modal>
</template>

<script>
import { formatGoodsType } from '@/utils/type'

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    type: {
      type: [String, Number],
    },
    goodsList: {
      type: Array,
      defaut: () => []
    },
    selectKeys: {
      type: Array,
      defaut: () => []
    },
    handlerOk: {
      type: Function,
    },
  },
  data() {
    return {
      canSplit: false,
      wrapperCol: { span: 18 },
      labelCol: { span: 6 },
      selectRows: [],
      isSplit: true,
      rowSelection: null,
      baseRowSelection: {
        selectedRowKeys: this.selectKeys || [],
        onSelect: (record, selected) => {
          if (selected) {
            this.rowSelection.selectedRowKeys.unshift(record.order_goods_id)
            this.selectRows.unshift(record)
          } else {
            this.rowSelection.selectedRowKeys = this.rowSelection.selectedRowKeys.filter(
              (el) => el != record.order_goods_id
            )
            this.selectRows = this.selectRows.filter(el => el.order_goods_id != record.order_goods_id)
          }
        },
        onSelectAll: (selected, selectedRows) => {
          this.selectRows = this.selectedRows
          if (selected) {
            this.rowSelection.selectedRowKeys = selectedRows.map(el => el.order_goods_id)
            this.selectRows = selectedRows
          } else {
            this.rowSelection.selectedRowKeys = []
            this.selectRows = []
          }
        },
      },
      columns: [
        {
          title: "产品属性",
          dataIndex: "goods_type",
          align: "center",
          width: 90,
          slots: {
            customRender: "type",
          },
          slotsType: "format",
          slotsFunc: (val) => formatGoodsType(val),
        },
        {
          title: "商品名称",
          dataIndex: "goods_name",
          align: "center",
          width: "30%",
        },
        {
          title: "口味",
          dataIndex: "flavor_name",
          align: "center",
          width: "20%",
        },
        {
          title: "尺寸",
          dataIndex: "specification_name",
          align: "center",
          width: "20%",
        },
        {
          title: "数量",
          dataIndex: "number",
          align: "center",
          width: 80,
        },
        {
          title: "网点库存",
          dataIndex: "outlets_stock",
          align: "center",
          width: 90,
        },
      ],
      tableData: [],
      baseList: [],
      total: 0,
    }
  },
  methods: {

    handleOk() {
      let _this = this
      if(this.canSplit){
        if (!this.isSplit || this.selectRows.length === 0) {
          this.$confirm({
            title: "温馨提示",
            content: '您确定不对无库存商品拆单？',
            okText: "确认",
            cancelText: "取消",
            onOk() {
              _this.$emit("ok", _this.isSplit, _this.selectRows)
              _this.$emit("update:show", false)
            },
          })
          return
        }
      }
      
      _this.$emit("ok", _this.isSplit, _this.selectRows)
      _this.$emit("update:show", false)
    },
  },
  watch:{
    type(){
      this.canSplit = this.type==2
      this.rowSelection = this.canSplit ? this.baseRowSelection : null
    }
  }
}
</script>

<style>
.ant-modal-body {
  position: relative;
}
</style>