<template>
  <div>
    <a-modal
      :visible.sync="show"
      title="选择网点"
      destroyOnClose
      width="850px"
      @cancel="$emit('update:show', false)"
      :footer="null"
    >
      <div class="modal-search-bar">
        <a-form-model
          layout="horizontal"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          ref="searchRef"
          :model="searchForm"
        >
          <a-row>
            <a-col :span="12">
              <a-form-model-item prop="outlets_name" label="网点名称">
                <a-input allowClear v-model="searchForm.outlets_name" placeholder="网点名称" @keydown.enter="handlerSearch"></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item style="margin-left:20px;">
                <a-button @click="handlerSearch" type="primary">搜索</a-button>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </div>
      <base-table
        ref="goodsTable"
        :columnsData="columns"
        rowKey="outlets_id"
        :tableData="tableData"
        :total="total"
        :customHeight="400"
        :getListFunc="initData"
        :page.sync="searchForm.page"
        :pageSize.sync="searchForm.page_count"
      >
        <template #operation="{ record }">
          <a-button type="link" @click="handlerSelect(record)">选择</a-button>
        </template>
      </base-table>
    </a-modal>
    <outlets-nogoods-modal
      :show.sync="isOuteletsNoGoods"
      :goodsList="noStockgoodsList"
      :type="type"
      @ok="handlerOutletNoGoodsSure"
    />
  </div>
</template>

<script>
import { 
  getOutletsList, 
  getPickupShopsList,
  getDeliveryOutletsList, 
  getShopNotStockGoodsList,
  getOutletsNotStockGoodsList
} from "@/api/customer"

import OutletsNogoodsModal from '../components/outlets-nogoods-modal.vue'

export default {
  components: {
    OutletsNogoodsModal
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    type: {
      type: [String, Number],
    },
    orderId: {
      type: [String, Number],
    },
    selectKeys: {
      type: Array,
    },
    handlerOk: {
      type: Function,
    },
    initList: {
      type: Function,
    },
  },
  data() {
    return {
      wrapperCol: { span: 18 },
      labelCol: { span: 6 },
      isOuteletsNoGoods: false,
      noStockgoodsList: [],
      activeOutlets: null,
      searchForm: {
        outlets_name: "",
        page: 1,
        page_count: 10,
      },
      columns: [],
      baseColumns: [
        {
          title: "类型",
          dataIndex: "outlets_type",
          align: "center",
          width: 80,
          slots: {
            customRender: "type",
          },
          slotsType: "format",
          slotsFunc: (val) => this.formatType(val),
        },
        {
          title: "网点名",
          dataIndex: "outlets_name",
          align: "center",
        },
        {
          title: "联系电话",
          dataIndex: "tel",
          align: "center",
          width: 130,
          slots: {
            customRender: "type",
          },
          slotsType: "format",
          slotsFunc: (val) => { return val.replace(",", "\n") },
        },
        {
          title: "覆盖范围",
          dataIndex: "cover_name",
          align: "center",
          width: 80,
        },
        {
          title: "有库存",
          dataIndex: "have_stock",
          align: "center",
          width: 80,
        },
        {
          title: "运费",
          dataIndex: "freight",
          align: "center",
          width: 80,
        },
        {
          title: "操作",
          dataIndex: "operation",
          align: "center",
          width: 80,
          slots: {
            customRender: "operation",
          },
        },
      ],
      tableData: [],
      baseList: [],
      total: 0,
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    formatType(val) {
      return val === 1 ? '门店' : '配送站'
    },
    async initData() {
      let res
      if (this.orderId) {
        if(this.type == 1){
          res = await getPickupShopsList({ order_no: this.orderId })
          this.columns = this.baseColumns.filter(item =>!["cover_name","freight"].includes(item.dataIndex))
        }else if(this.type == 2){
          res = await getDeliveryOutletsList({ order_no: this.orderId })
          this.columns = this.baseColumns
        }
      } else {
        // test ？？？ 没处理数据啊
        await getOutletsList(this.searchForm)
      }
      this.tableData = res.data.list.map(item => {
        item.cover_name = item.is_cover === 1 ? '否' : '是'
        item.have_stock = item.have_stock === 1 ? '否' : '是'

        return item
      })
      this.baseList = res.data.list

      this.total = res.data.total_count
    },
    // 保存
    handlerSearch() {
      this.tableData = this.baseList.filter(item => item.outlets_name.includes(this.searchForm.outlets_name))
    },
    async handlerSelect(row) {
      this.activeOutlets = row
      let res
      if(this.type==1){
        // 门店有库存么？没库存提示
        res = await getShopNotStockGoodsList({ order_no: this.orderId, outlets_id: row.outlets_id })
      }else if(this.type==2){
        // 配送站有库存么？没库存提示（引导拆单）
        res = await getOutletsNotStockGoodsList({ order_no: this.orderId, outlets_id: row.outlets_id })
      }

      if (res && res.code == 0) {
        if(res.data.list.length){
          // test 过滤蛋糕有个问题：列表就不显示了
          // this.noStockgoodsList = res.data.list.filter(el=>el.goods_type!=3)
          this.noStockgoodsList = res.data.list
          this.isOuteletsNoGoods = true
        }else {
          this.$emit("ok", row)
          this.$emit("update:show", false)
        }
      }
    },

    // 如果切换的负责网点没有库存，则对勾选的商品进行拆单赋值
    handlerOutletNoGoodsSure(isSplit, rows) {
      // 如果确认拆单
      this.$emit("getSplitGoods", {
        outlets: this.activeOutlets,
        goods: rows,
        isSplit: isSplit
      })
      this.$emit("update:show", false)
    },
  },
}
</script>

<style>
.ant-modal-body {
  position: relative;
}
</style>