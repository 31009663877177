<template>
  <div>
    <a-modal
      :visible.sync="show"
      title="收银推送记录"
      destroyOnClose
      width="940px"
      @cancel="$emit('update:show', false)"
      :footer="null"
    >
      <base-table class="mb-2"
        noScrollY
        :columnsData="columns"
        :customHeight="400"
        :tableData="tableData">
        <template #push_status="{record}">
          <a-tooltip placement="topLeft" :title="record['return_data']">
            <a-button class="p-0" type="link">{{record.push_status}}</a-button>
          </a-tooltip>
        </template>
      </base-table>
      <div class="flex justify-between mb-2">
        <div></div>
        <a-button v-if="hasUnsuccPush" @click="retryPushAll">重推该订单</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import {
  getPushList,
  pushRetry
} from "@/api/system/cashier.js"

export default {
  components: {
    
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    orderId: {
      type: [String, Number],
    },
  },
  data() {
    return {
      columns: [
        {
          title: 'id',
          dataIndex: 'id',
          align: 'center',
          width: 80,
        },{
          title: '收银软件',
          dataIndex: 'offline_cash_register_name',
          align: 'center',
          width: 90,
        },{
          title: '订单网点',
          dataIndex: 'outlets_name',
          align: 'center',
          width: 100,
        },{
          title: '订单状态',
          dataIndex: 'order_status',
          align: 'center',
          width: 80,
        },{
          title: '任务生成时间',
          dataIndex: 'add_time',
          align: 'center',
          width: 170,
        },{
          title: '最后推送时间',
          dataIndex: 'finish_push_time',
          align: 'center',
          width: 170,
        },{
          title: '最后推送状态',
          dataIndex: 'push_status',
          align: 'center',
          width: 100,
          slots: { customRender: "push_status" },
        },
      ],
      tableData: [],
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    async initData(){
      const { data, code } = await getPushList({
        order_no: this.orderId,
        page_count: 100
      })
      if (code === 0) {
        this.tableData = data.list
        this.hasUnsuccPush = data.list.some(el=>{
          return el.push_status=="推送失败" || el.push_status=="未推送"
        })
      }
    },
    
    async retryPushAll(){
      const { code } = await pushRetry({
        order_no: this.orderId,
      })
      if (code === 0) {
        this.initData()
      }
    },

  },
}
</script>

<style>
.ant-modal-body {
  position: relative;
}
</style>