<template>
<div class="scroll-y-con">
  <div class="top-title">
    <div>{{`订单ID: ${formState.order_no} (${formatUserOrderStatus(data.order_status, orderStatus_orderType||orderType)})`}}
      <template v-if="data.order_status == 1 && data.order_source == 10">
        <a-button type="link" @click="showPay=true">选择支付方式</a-button>
        <pay-tip />
      </template>
      <template v-if="isOtherPlatOrder">
        <a-button type="link" class="ml-4 p-0" 
          @click="openOtherPlatOrder">查看第三方订单</a-button>
      </template>
    </div>
    <div>
      <span class="text-sm">
        <span style="font-weight:400;">订单来源：</span>
        <template>{{formatOrderSource(data.order_source)}}</template>
        <template v-if="data.order_source==2"> - {{data.source_outlets_name}}</template>
        <template v-if="data.order_source==15"> - {{data.add_outlets_name}}</template>
        <template v-if="data.is_cash_delivery_order==2">（货到付款）</template>
        <span v-if="data.place_order_enterprise_name" class="ml-1" style="font-weight:400;">（大客户-{{data.place_order_enterprise_name}}）</span>
      </span>
      <a-button
        v-if="data.order_status > 0 && data.order_status < 30"
        type="link" class="ml-6" style="padding:0;"
        @click="handlerCancel"
      >取消订单</a-button>
      <a-tag v-if="data.status == -1" class="ml-4 mr-0" color="red">订单已删除</a-tag>
    </div>
  </div>

  <div class="page-content">
    <div v-if="isInBlackList" class="mb-2" style="background:#eee; padding:10px 20px;">
      <div>
        <span style="color:#f00;">此用户是黑名单用户</span>
      </div>
      <div class="flex align-center mt-2">
        <span>备注说明: </span>
        <span class="ml-2">{{ blackListRemarks }}</span>
      </div>
    </div>
    
    <div>
      <a-row>
        <a-col :span="8">
          <span>{{ data.order_source >= 10 ? '下单人' : '昵称'}}: </span>
          <span class="ml-2">{{ data.place_order_name }}</span>
          <a-button v-if="data.member_id && !onlyRead" class="ml-2" style="padding:0;"
              type="link" @click="openUserDetail">查看用户</a-button>
        </a-col>
        <a-col :span="8">
          <span>{{data.order_source >= 10 ? '下单手机号' : '绑定手机号'}}: </span>
          <span class="ml-2">{{ data.place_order_phone }}</span>
        </a-col>
        <a-col :span="8">
          <span>下单时间: </span>
          <span class="ml-2">{{ data.order_add_time }}</span>
        </a-col>
      </a-row>

      <a-row class="mt-3">
        <template v-if="isCakeAudit">
          <a-col class="flex items-start" :span="24">
            <span style="width:80px; margin-top: 5px;">下单备注:</span>
            <span class="w-full">
              <a-textarea
                :class="{ 'has-update': hasDataUpdate('order_remarks', data.order_remarks) }"
                v-model="formState.order_remarks"
                placeholder="会打印在小票上、顾客可见"
                :auto-size="{ minRows: 1, maxRows: 6 }" />
            </span>
          </a-col>
        </template>
        <template v-else>
          <a-col :span="20">
            <span>下单备注: </span>
            <span class="ml-2">{{ data.order_remarks }}</span>
          </a-col>
          <a-col v-if="data.member_id" :span="4" style="text-align:right;">
            <a-button v-if="!onlyRead" type="link" class="p-0" @click="isShowAddConpon=true">补偿优惠券</a-button>
            <span v-if="data.compensate_coupon_list && data.compensate_coupon_list.length">(已发<a-button type="link" class="p-0" 
              @click="isShowOptLog=true">{{data.compensate_coupon_list.length}}</a-button>张)</span>
          </a-col>
        </template>
      </a-row>
      <!-- 客服备注 -->
      <a-row class="mt-3">
        <a-col class="flex items-start" :span="24">
          <span style="width:80px; margin-top: 5px;">客服备注:</span>
          <p v-if="!hasAuthAudit">{{ data.customer_service_remarks || "无" }}</p>
          <div v-else class="w-full">
            <a-textarea
                v-model="formState.customer_service_remarks"
                placeholder="顾客不可见"
                :auto-size="{ minRows: 1, maxRows: 6 }" />
            <div v-if="!isCakeAudit">
              <a-button class="mt-2 mr-2" type="primary" size="small" 
                  @click="saveCustomerRemarks">保存备注</a-button>
              <span style="color:#aaa;font-size:12px;">*会推送给收银、会触发门店自动打印（订单完成后除外）</span>
            </div>
          </div>
        </a-col>
      </a-row>
      <!-- 内部备注 -->
      <a-row class="mt-3">
        <a-col class="flex items-start" :span="24">
          <span style="width:80px; margin-top: 5px;">内部备注:</span>
          <p v-if="!hasAuthAudit">{{ data.private_remarks || "无" }}</p>
          <div v-else class="w-full">
            <a-textarea
                v-model="formState.private_remarks"
                placeholder="顾客不可见"
                :auto-size="{ minRows: 1, maxRows: 6 }" />
            <div>
              <a-button class="mt-2 mr-2" type="primary" size="small" 
                  @click="savePrivateRemarks">保存备注</a-button>
              <span style="color:#aaa;font-size:12px;">*不会推送给收银、不会触发门店自动打印</span>
            </div>
          </div>
        </a-col>
      </a-row>

      <a-row class="mt-3">
        <a-col :span="24">
          <span style="width:80px; margin-top: 5px;">门店备注:</span>
          <span class="ml-2">{{ data.outlets_remarks || "无" }}</span>
        </a-col>
      </a-row>
      <a-divider />
      <a-row class="mt-3">
        <a-col :span="5">
          <span>订单总额: </span>
          <span>{{ data.order_amount }}元</span>
        </a-col>
        <a-col :span="5">
          <template v-if="Number(data.pack_amount)>0 && Number(data.freight_amount)>0">
            <span>保温袋、运费: </span>
            <span>{{ data.pack_amount }}元、{{ data.freight_amount }}元</span>
          </template>
          <template v-else>
            <template v-if="isDrink">
              <span>保 温 袋：</span>
              <span>{{ data.pack_amount }}元</span>
            </template>
            <template v-else>
              <span>运费金额: </span>
              <span>{{ data.freight_amount }}元</span>
            </template>
          </template>
        </a-col>
        <a-col :span="6">
          <span style="width:110px" class="inline-block text-right">全场活动优惠：</span>
          <span>{{ data.all_event_offers_amount }}元</span>
        </a-col>
        <a-col :span="8">
          <span>支付状态: </span>
          <span v-if="data.pay_status == 2">
            已支付
            <span style="font-size:12px;color:#999;"
              >（{{ data.order_financial_account_name }}）</span>
          </span>
          <a-tag v-if="data.pay_status == 1" class="mr-0" color="red">未支付</a-tag>
          <!-- // test 支付方式没加呢 -->
          <template v-if="data.is_cash_delivery == 2">
            <span v-if="isCakeAudit" class="btn-link ant-btn-link" @click="showPaySub">
              <a-select
                ref="payTypeSelect"
                class="ml-2"
                default-value="formState.pay_subtype"
                style="width:140px; opacity:0; visibility:hidden; position:absolute; margin-top:-10px;"
                v-model="formState.pay_subtype">
                <a-select-option key="" value="">未知</a-select-option>
                <a-select-option v-for="item in subCODPayTypeList"
                    :key="item.id"
                    :value="item.id">{{ item.name }}</a-select-option>
              </a-select>
              <template>
                （货到付款-{{getCODPayType(formState.pay_subtype)}} <svg viewBox="64 64 896 896" data-icon="down" width="0.8em" height="0.75em" fill="currentColor" aria-hidden="true" focusable="false" class=""><path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path></svg>）
              </template>
            </span>
            <span v-else>
              <template v-if="data.pay_type==10 && data.pay_subtype!=0"
                  >（货到付款-{{getCODPayType(data.pay_subtype)}}）</template>
              <template v-else
                  >（货到付款）</template>
            </span>
          </template>
            
        </a-col>
      </a-row>

      <a-row class="mt-3">
        <a-col :span="5">
          <span>商品金额: </span>
          <span>{{ data.goods_amount }}元</span>
        </a-col>
        <a-col :span="5">
          <span class="inline-block text-right">优 惠 券：</span>
          <span>{{ data.coupon_offers_amount }}元</span>
        </a-col>
        <!-- <a-col :span="6">
          <span style="width:110px" class="inline-block text-right">会员折扣：</span>
          <span>{{ data.vip_discount_amount }}元</span>
        </a-col> -->
        <a-col :span="6">
          <template v-if="Number(data.private_offers_amount) > 0">
            <span style="width:110px" class="inline-block text-right">员工折扣：</span>
            <span>{{ data.private_offers_amount }}元</span>
          </template>
          <template v-else-if="Number(data.other_offers_amount) > 0">
            <span style="width:110px" class="inline-block text-right">其他折扣：</span>
            <span>{{ data.other_offers_amount }}元</span>
          </template>
        </a-col>
        <a-col :span="8">
          <span>支付时间: </span>
          <span>{{ data.pay_time }}</span>
        </a-col>
      </a-row>

      <div class="mt-3">
        <base-table :columnsData="pay_column" :customHeight="100" :tableData="payData" rowKey="key">
          <!-- <template #cash_delivery_amount="{record}">
            <span v-if="data.pay_status == 2 && data.pay_type == 10"
              >{{getCODPayType(data.pay_subtype)}} {{record.cash_delivery_amount}}</span>
            <span v-else>{{record.cash_delivery_amount}}</span>
          </template> -->
        </base-table>
      </div>

      <a-divider />

<!-- 子订单数据：审核 -->
      <a-collapse :default-active-key="['1', '2']" v-if="isCakeAudit || isDrinkAudit">
    <!-- 网点子订单 -->
        <a-collapse-panel
          :show-arrow="false"
          key="1"
          :header="`【${outletOrder.suborder_type === 1 ? '自提' : '配送'}】子订单：${outletOrder.suborder_no}(${outletOrder.suborder_statusname})`"
        >
          <template slot="extra">
            <a-button v-if="showSplitBtn"
              class="ant-btn ant-btn-primary ant-btn-sm" type="primary"
              @click.stop="handlerSplit"
            >拆分订单</a-button>
            <a v-if="data.order_status !=-1 && outletOrder.suborder_type == 2 && outletOrder.suborder_status >= 5"
              class="pt-4 pb-4 ml-2" type="link"
              @click.stop="handlerQuery(outletOrder.suborder_type)"
            >配送进度</a>
          </template>
          <a-row type="flex" justify="space-between" align="middle">
            <a-col :span="6">
              <span class="text-right" style="width:70px;">取货方式：</span>
              <span>{{ outletOrder.suborder_type === 1 ? '自提' : '配送' }}</span>
              <a-button class="ml-2" type="link" size="small"
                v-if="isCakeAudit" @click="showTransition"
                >{{ outletOrder.suborder_type == 1 ? '转配送' : '转自提' }}</a-button>
            </a-col>
            <a-col :span="7">
              <span class="text-right" style="width:70px;">{{ outletOrder.suborder_type === 1 ? '自提门店' : '配送网点' }}：</span>
              <span>{{ outletOrder.outlets_name }}</span>
              <a-button class="ml-2" type="primary" size="small"
                  @click="handlerSelectOutlets">修改</a-button>
            </a-col>
            <a-col :span="11">
              <div class="flex items-center">
                <span>{{ outletOrder.suborder_type === 2 ? '收货时间' : '取货时间' }}：</span>
                <time-picker2 v-if="formState.goods_time" style="flex:1;"
                  :class="{ 'has-update': isTimeUpdate }"
                  :duration="goods_time_duration"
                  :value.sync="formState.goods_time" />
              </div>
            </a-col>
          </a-row>
          <!--如果是自提订单 显示这些-->
          <template v-if="outletOrder.suborder_type === 1">
            <a-row class="mt-3">
              <a-col class="flex items-center" :span="6">
                <span class="text-right" style="width:70px;">取货人：</span>
                <span>
                  <a-input
                    :class="{ 'has-update': hasDataUpdate('pick_up_info.recipient_name', outletOrder.pick_up_info.recipient_name) }"
                    v-model="outletOrder.pick_up_info.recipient_name"
                  />
                </span>
              </a-col>
              <a-col class="flex items-center" :span="7">
                <span class="text-right" style="width:70px;">手机号：</span>
                <span>
                  <a-input
                    :class="{ 'has-update': hasDataUpdate('pick_up_info.recipient_phone', outletOrder.pick_up_info.recipient_phone) }"
                    v-model="outletOrder.pick_up_info.recipient_phone"
                  />
                </span>
              </a-col>
              <a-col :span="11"></a-col>
            </a-row>
          </template>
          <!--如果是配送订单 显示这些-->
          <template v-if="outletOrder.suborder_type === 2">
            <a-row class="mt-3" v-if="outletOrder.delivery_info.contacts_phone">
              <a-col class="flex items-center" :span="6">
                <span class="text-right" style="width:70px;">联系人：</span>
                <span>
                  <a-input
                    style="width:120px;"
                    disabled
                    :class="{ 'has-update': hasDataUpdate('delivery_info.contacts_name', outletOrder.delivery_info.contacts_name) }"
                    v-model="outletOrder.delivery_info.contacts_name"
                  />
                </span>
              </a-col>
              <a-col class="flex items-center" :span="7">
                <span class="text-right" style="width:70px;">联系电话：</span>
                <span>
                  <a-input
                    style="width:170px;"
                    disabled
                    :class="{ 'has-update': hasDataUpdate('delivery_info.contacts_phone', outletOrder.delivery_info.contacts_phone) }"
                    v-model="outletOrder.delivery_info.contacts_phone"
                  />
                </span>
              </a-col>
              <a-col :span="8"></a-col>
            </a-row>

            <a-row class="mt-3">
              <a-col class="flex items-center" :span="6">
                <span class="text-right" style="width:70px;">收货人：</span>
                <span>
                  <a-input style="width:120px;" v-model="outletOrder.delivery_info.consignee_name" />
                </span>
              </a-col>
              <a-col class="flex items-center" :span="7">
                <span class="text-right" style="width:70px;">手机号：</span>
                <span>
                  <a-input style="width:170px;" v-model="outletOrder.delivery_info.consignee_phone" />
                </span>
              </a-col>
              <a-col class="flex items-center" :span="11">
                <span class="text-right" style="width:70px;">收件地址：</span>
                <!-- <div class="flex flex-1" style="position:relative;overflow:hidden;"
                  id="addr-search"
                  :data-clipboard-text="outletOrder.delivery_info.consignee_address" 
                  @dblclick="addrSearchCopy">
                  <a-select
                    show-search
                    v-model="outletOrder.delivery_info.consignee_address"
                    placeholder="请输入所在地址"
                    style="flex:1;overflow:hidden;"
                    :default-active-first-option="false"
                    :show-arrow="false"
                    :filter-option="false"
                    :not-found-content="null"
                    @search="handleSearch"
                    @change="handleChange"
                  >
                    <a-select-option v-for="d in addressList" :value="d.id" :key="d.id">{{ d.title }}<span class="addr-region">{{ d.region }}</span></a-select-option>
                  </a-select>
                </div> -->

                <!-- <div class="flex flex-1" style="position:relative;overflow:hidden;"
                  id="addr-search"
                  :data-clipboard-text="outletOrder.delivery_info.consignee_address" 
                  @dblclick="addrSearchCopy">
                  <a-select
                    show-search
                    v-model="outletOrder.delivery_info.consignee_address"
                    placeholder="请输入所在地址"
                    style="flex:1;overflow:hidden;"
                    :default-active-first-option="false"
                    :show-arrow="false"
                    :filter-option="false"
                    :not-found-content="null"
                    @search="handleSearch"
                    @change="handleChange"
                    @blur="handleAddrBlur"
                    :autofocus="addrSearchAutoFocus"
                  >
                    <a-select-option v-for="d in addressList" :value="d.id" :key="d.id">{{ d.title }}<span class="addr-region">{{ d.region }}</span></a-select-option>
                  </a-select>
                  <div class="flex-1" style="position:absolute;background: #fff;width:100%;height:100%; padding:0 11px;border: 1px solid #d9d9d9; border-radius: 4px;overflow:hidden;white-space:nowrap;text-overflow: ellipsis;line-height:30px;"
                    v-if="!addrSearchAutoFocus"
                    @click="addrSearchFocus"
                    >{{outletOrder.delivery_info.consignee_address}}</div>
                </div> -->
                <a-select
                  show-search
                  v-model="outletOrder.delivery_info.consignee_address"
                  placeholder="请输入所在地址"
                  style="flex:1;overflow:hidden;"
                  :default-active-first-option="false"
                  :show-arrow="false"
                  :filter-option="false"
                  :not-found-content="null"
                  @search="handleSearch"
                  @change="handleChange"
                >
                  <a-select-option v-for="d in addressList" :value="d.id" :key="d.id">{{ d.title }}<span class="addr-region">{{ d.region }}</span></a-select-option>
                </a-select>
                <a-input style="width:150px" v-model="outletOrder.delivery_info.consignee_address_detail" allowClear placeholder="请输入门牌号"></a-input>

              </a-col>
            </a-row>
          </template>

          <div class="flex justify-between mt-4">
            <span class="text-sm font-bold text-black">购买商品</span>
          </div>
          <base-table v-if="isDrink"
            ref="orderDetilTableRef"
            id="orderDetilTableID"
            class="mt-2"
            :customHeight="300"
            :columnsData="detail_columns_drink"
            rowKey="sku_id"
            :tableData="outletOrder.merge_goods_list"></base-table>
          <base-table v-else
            ref="orderDetilTableRef"
            id="orderDetilTableID"
            class="mt-2"
            :customHeight="300"
            :columnsData="detail_columns"
            rowKey="sku_id"
            :tableData="leftOutletGoodsList"></base-table>

          <!--蛋糕配件-->
          <div class="mt-3" v-if="cakeList && cakeList.length > 0">
            <div class="text-sm font-bold text-black">蛋糕配件</div>
            <div class v-for="(item,i) in cakeList" :key="i">
              <div class="mt-2 flex items-center">
                <div>
                  <span class="font-bold">{{ item.goods_name }}</span>
                  <span class="ml-2" style="color:#aaa;">{{getCakeSkuInfo(item.order_goods_id)['flavor_name']}} / {{getCakeSkuInfo(item.order_goods_id)['specification_name']}}</span>
                </div>
                <div class="ml-6">
                  <span class="text-right" style="width:70px;">蜡烛:</span>
                  <!-- <a-cascader
                    class="ml-2"
                    :class="{ 'has-update': hasCakeUpdate('birthday_candle', item.birthday_candle.join(':'), i) }"
                    :options="candleOptions"
                    v-model="item.birthday_candle"
                  />-->
                  <birthday-candle :class="{ 'has-update': hasCakeUpdate('birthday_candle', item.birthday_candle, i) }" :value.sync="item.birthday_candle"></birthday-candle>
                </div>
                <div class="ml-2">
                  <span class="text-right" style="width:100px;">餐具数量:</span>
                  <a-select
                    class="ml-2"
                    :class="{ 'has-update': hasCakeUpdate('diners_number', item.diners_number, i) }"
                    default-value="1"
                    style="width:100px"
                    v-model="item.diners_number"
                  >
                    <a-select-option
                      v-for="(item, index) in tableWare"
                      :value="item"
                      :key="index"
                    >{{ item }}人份</a-select-option>
                  </a-select>
                </div>
                <div class="ml-2 flex items-center flex-1">
                  <span class="text-right" style="width:70px;">生日牌:</span>
                  <a-input
                    class="ml-2 flex-1"
                    :class="{ 'has-update': hasCakeUpdate('birthday_card', item.birthday_card, i) }"
                    style="max-width:300px;"
                    v-model="item.birthday_card"
                  />

                </div>
              </div>
            </div>
          </div>

          <template v-if="giftList.length > 0">
            <a-divider />
            <div class="flex justify-between mt-4">
              <span class="text-sm font-bold text-black">赠品列表</span>
            </div>
            <base-table
              class="mt-2"
              :customHeight="200"
              :columnsData="gift_columns"
              rowKey="sku_id"
              :tableData="giftList"
            ></base-table>
          </template>

          <template v-if="conponList.length > 0">
            <div class="flex justify-between mt-4">
              <span class="text-sm font-bold text-black">赠券列表</span>
            </div>
            <base-table
              class="mt-2"
              :customHeight="200"
              :columnsData="conpon_columns"
              rowKey="id"
              :tableData="conponList"
            ></base-table>
          </template>
        </a-collapse-panel>
    <!-- 快递子订单 -->
        <a-collapse-panel key="2" v-if="isHaveExpress"
          :header="`【快递】子订单：${expressOrder.suborder_no}(${expressOrder.suborder_statusname})`"
          :show-arrow="false"
        >
          <template slot="extra">
            <!-- <a class="pt-4 pb-4 mr-4"
              v-if="hasAuthTryExpress && (expressOrder.suborder_status==2||expressOrder.suborder_status==3)"
              @click.stop="handlerTryFinish(expressOrder.suborder_no)"
            >刷新完成状态</a> -->
            <a v-if="data.order_status !=-1 && expressOrder.suborder_status >= 2"
              class="pt-4 pb-4"
              type="link"
              @click.stop="handlerQuery(expressOrder.suborder_type)"
            >快递进度</a>
          </template>
          <template>
            <a-row class="mt-3">
              <a-col :span="6">
                <span style="width:70px">子订单金额：</span>
                <span>{{ expressOrder.suborder_amount }}元</span>
              </a-col>

              <a-col class="flex items-center" :span="7">
                <span class="text-right" style="width:70px;">拆单时间：</span>
                <span>{{ expressOrder.suborder_add_time }}</span>
              </a-col>
            </a-row>

            <a-row class="mt-3">
              <a-col class="flex items-center" :span="6">
                <span class="text-right" style="width:85px;">收货人：</span>
                <span>
                  <a-input style="width:120px;" v-model="expressOrder.express_info.consignee_name" />
                </span>
              </a-col>
              <a-col class="flex items-center" :span="7">
                <span class="text-right" style="width:70px;">手机号：</span>
                <span>
                  <a-input style="width:170px;" v-model="expressOrder.express_info.consignee_phone" />
                </span>
              </a-col>
              <a-col class="flex items-center" :span="11">
                <span class="text-right" style="width:70px;">收件地址：</span>
                <a-select
                  show-search
                  v-model="expressOrder.express_info.consignee_address"
                  placeholder="请输入所在地址"
                  style="flex:1;width:0;"
                  :default-active-first-option="false"
                  :show-arrow="false"
                  :filter-option="false"
                  :not-found-content="null"
                  @search="handleSearch2"
                  @change="handleChange2"
                >
                  <a-select-option v-for="d in addressList2" :value="d.id" :key="d.id">{{ d.title }}<span class="addr-region">{{ d.region }}</span></a-select-option>
                </a-select>
                <a-input style="width:150px" v-model="expressOrder.express_info.consignee_address_detail" allowClear placeholder="请输入门牌号"></a-input>
              </a-col>
            </a-row>

            <div class="flex justify-between mt-4">
              <span class="text-sm font-bold text-black">快递商品</span>
            </div>
            <base-table
              ref="orderDetilTableRef"
              id="orderDetilTableID"
              :columnsData="detail_columns"
              :customHeight="300"
              rowKey="sku_id"
              class="mt-2"
              :tableData="expressOrder.goods_list"
            ></base-table>
          </template>
        </a-collapse-panel>
    <!-- 拆单操作 -->
        <a-collapse-panel key="2" v-if="splitGoodsList.length"
          header="【快递】子订单：新拆子订单"
          :show-arrow="false"
        >
          <template>
            <a-row class="mt-3">
              <a-col class="flex items-center" :span="6">
                <span>子订单金额：</span>
                <span>{{ totalPrice }}元</span>
              </a-col>
              <a-col class="flex items-center" :span="7">
                <span>取货方式：</span>
                <span>快递</span>
              </a-col>
            </a-row>

            <a-row class="mt-3">
              <a-col class="flex items-center" :span="6">
                <span>收货人：</span>
                <span>
                  <a-input style="width:120px;" v-model="formState.express_consignee_name" />
                </span>
              </a-col>
              <a-col class="flex items-center" :span="7">
                <span>手机号：</span>
                <span>
                  <a-input  style="width:170px;" v-model="formState.express_consignee_phone" />
                </span>
              </a-col>
              <a-col class="flex items-center" :span="11">
                <span  class="text-right" style="width:70px;">收件地址：</span>
                <a-select
                  show-search
                  v-model="formState.express_consignee_address"
                  placeholder="请输入所在地址"
                  style="flex:1;width:0;"
                  :default-active-first-option="false"
                  :show-arrow="false"
                  :filter-option="false"
                  :not-found-content="null"
                  @search="handleSearch3"
                  @change="handleChange3"
                >
                  <a-select-option v-for="d in addressList3" :value="d.id" :key="d.id">{{ d.title }}<span class="addr-region">{{ d.region }}</span></a-select-option>
                </a-select>
              <a-input style="width:150px" v-model="formState.consignee_address_detail" allowClear placeholder="请输入门牌号"></a-input>

              </a-col>
            </a-row>
            <div class="flex justify-between mt-4">
              <span class="text-sm font-bold text-black">拆单商品</span>
            </div>
            <base-table
              ref="orderDetilTableRef"
              id="orderDetilTableID"
              :columnsData="detail_columns"
              :customHeight="300"
              rowKey="sku_id"
              class="mt-2"
              :tableData="splitGoodsList"
            ></base-table>
          </template>
        </a-collapse-panel>
      </a-collapse>

<!-- 子订单数据：详情 -->
      <a-collapse :default-active-key="['1', '2']" v-else>
        <!-- 折叠栏：网点子订单 -->
        <a-collapse-panel
          :show-arrow="false"
          key="1"
          v-if="outletOrder && outletOrder.suborder_no"
          :header="`【${outletOrder.suborder_type === 1 ? '自提子订单' : '配送子订单'}】: ${outletOrder.suborder_no}(${outletOrder.suborder_statusname})`"
        >
          <template slot="extra">
            <a v-if="data.order_status !=-1 && outletOrder.suborder_type == 2 && outletOrder.suborder_status >= 5"
              class="pt-4 pb-4"
              type="link"
              @click.stop="handlerQuery(outletOrder.suborder_type)"
            >配送进度</a>
            <span v-if="outletOrder.suborder_type == 1 && outletOrder.pick_up_info.pick_up_code">提货码：{{outletOrder.pick_up_info.pick_up_code}}</span>
          </template>
          <a-row class="mt-3">
            <a-col :span="6">
              <span class="order-detail-title">取货方式: </span>
              <span>{{ outletOrder.suborder_type === 1 ? '自提' : '配送' }}</span>
            </a-col>
            <a-col :span="7">
              <span class="order-detail-title">负责网点: </span>
              <span>{{ outletOrder.outlets_name }}</span>
            </a-col>
            <a-col :span="11">
              <span>收货时间: </span>
              <span
                v-if="outletOrder.suborder_type === 1"
              >{{ formatTime(outletOrder.pick_up_info.pick_up_goods_time_start) }}-{{ formatTime(outletOrder.pick_up_info.pick_up_goods_time_end.split(' ')[1]) }}</span>
              <span
                v-else-if="outletOrder.suborder_type === 2"
              >{{ formatTime(outletOrder.delivery_info.delivery_time_start) }}-{{ formatTime(outletOrder.delivery_info.delivery_time_end.split(' ')[1]) }}</span>
            </a-col>
          </a-row>

          <!--如果是自提订单 显示这些-->
          <template v-if="outletOrder.suborder_type === 1">
            <a-row class="mt-3">
              <a-col :span="6">
                <span class="order-detail-title">取货人: </span>
                <span>{{ outletOrder.pick_up_info.recipient_name }}</span>
              </a-col>
              <a-col :span="7">
                <span class="order-detail-title">手机号: </span>
                <span>{{ outletOrder.pick_up_info.recipient_phone }}</span>
              </a-col>
              <a-col :span="11"></a-col>
            </a-row>
          </template>
          <!--如果是配送订单 显示这些-->
          <template v-if="outletOrder.suborder_type === 2">
            <a-row class="mt-3" v-if="outletOrder.delivery_info.contacts_phone">
              <a-col class="flex items-center" :span="6">
                <span class="order-detail-title">联系人: </span>
                <span>{{ outletOrder.delivery_info.contacts_name }}</span>
              </a-col>
              <a-col class="flex items-center" :span="7">
                <span class="order-detail-title">联系电话: </span>
                <span>{{ outletOrder.delivery_info.contacts_phone }}</span>
              </a-col>
              <a-col :span="11"></a-col>
            </a-row>

            <a-row class="mt-3">
              <a-col :span="6">
                <span class="order-detail-title">收货人: </span>
                <span>{{ outletOrder.delivery_info.consignee_name }}</span>
              </a-col>
              <a-col :span="7">
                <span class="order-detail-title">手机号: </span>
                <span>{{ outletOrder.delivery_info.consignee_phone }}</span>
              </a-col>
              <a-col :span="11">
                <span>收件地址: </span>
                <span>{{ outletOrder.delivery_info.consignee_address }}</span>
              </a-col>
            </a-row>
          </template>

          <div class="flex justify-between mt-4 mb-4">
            <span class="text-sm font-bold text-black">购买商品</span>
          </div>
          <base-table v-if="isDrink"
            class="shop-table-class"
            ref="orderDetilTableRef"
            id="orderDetilTableID"
            :columnsData="detail_columns_drink"
            rowKey="sku_id"
            :customHeight="400"
            :tableData="outletOrder.merge_goods_list">
            <template #is_refund="{ text }">
              <a-tag v-if="text==2" class="mr-0" color="red">已退费</a-tag>
            </template>
          </base-table>
          <base-table v-else
            class="shop-table-class"
            ref="orderDetilTableRef"
            id="orderDetilTableID"
            :columnsData="detail_columns"
            rowKey="sku_id"
            :customHeight="400"
            :tableData="outletOrder.goods_list">
            <template #is_refund="{ text }">
              <a-tag v-if="text==2" class="mr-0" color="red">已退费</a-tag>
            </template>
          </base-table>

          <div
            class="mt-2 justify-between"
            v-if="outletOrder.cake_accessory_list && outletOrder.cake_accessory_list.length > 0"
          >
            <div>
              <span class="text-sm font-bold text-black">蛋糕配件</span>
            </div>
            <div v-for="(item,i) in outletOrder.cake_accessory_list" :key="i">
              <div class="flex mt-2">
                <div>
                  <span class="font-bold">{{ item.goods_name }}</span>
                  <span class="ml-2" style="color:#aaa;">{{getCakeSkuInfo(item.order_goods_id)['flavor_name']}} / {{getCakeSkuInfo(item.order_goods_id)['specification_name']}}</span>
                </div>
                <div class="ml-10">蜡烛: {{ formatBirthdayCandle(item.birthday_candle) }}</div>
                <div class="ml-10">餐具数量: {{ item.diners_number }}人份</div>
                <div class="ml-10">生日牌: {{ item.birthday_card }}</div>
              </div>
            </div>
          </div>
          <template v-if="giftList.length > 0">
            <div class="flex justify-between mt-4">
              <span class="text-sm font-bold text-black">赠品</span>
            </div>
            <base-table
              ref="orderDetilTableRef"
              id="orderDetilTableID"
              :columnsData="gift_columns"
              rowKey="sku_id"
              :tableData="giftList"
            ></base-table>
          </template>
          <template v-if="conponList.length > 0">
            <div class="flex justify-between mt-4">
              <span class="text-sm font-bold text-black">赠券</span>
            </div>
            <base-table
              class="shop-table-class mt-2"
              ref="orderDetilTableRef"
              id="orderDetilTableID"
              :columnsData="conpon_columns"
              row-key="id"
              :tableData="conponList"
            ></base-table>
          </template>
        </a-collapse-panel>

        <!-- 折叠栏：快递子订单 -->
        <a-collapse-panel
          key="2"
          v-if="isHaveExpress"
          :header="`【快递】子订单：${expressOrder.suborder_no}(${expressOrder.suborder_statusname})`"
          :show-arrow="false"
        >
          <template slot="extra">
            <!-- <a class="pt-4 pb-4 mr-4"
              v-if="hasAuthTryExpress && (expressOrder.suborder_status==2||expressOrder.suborder_status==3)"
              @click.stop="handlerTryFinish(expressOrder.suborder_no)"
            >刷新完成状态</a> -->
            <a v-if="data.order_status !=-1 && expressOrder.suborder_status >= 2"
              class="pt-4 pb-4"
              type="link"
              @click.stop="handlerQuery(3)"
            >快递进度</a>
          </template>
          <!--如果是快递订单 显示这些-->
          <template>
            <a-row class="mt-3">
              <a-col class="flex items-center" :span="8">
                <span class="order-detail-title">订单金额：</span>
                <span>{{ expressOrder.suborder_amount }}元</span>
              </a-col>
              <a-col class="flex items-center" :span="8">
                <span>取货方式：</span>
                <span>快递</span>
              </a-col>
            </a-row>
            <a-row class="mt-3">
              <a-col :span="8">
                <span class="order-detail-title">收货人：</span>
                <span>{{ expressOrder.express_info.consignee_name }}</span>
              </a-col>
              <a-col :span="8">
                <span class="order-detail-title">手机号：</span>
                <span>{{ expressOrder.express_info.consignee_phone }}</span>
              </a-col>
              <a-col :span="8">
                <span class="order-detail-title">收件地址：</span>
                <span>{{ expressOrder.express_info.consignee_address }}</span>
              </a-col>
            </a-row>

            <div class="flex justify-between mt-4">
              <span class="text-sm font-bold text-black">快递商品</span>
            </div>
            <base-table
              ref="orderDetilTableRef"
              id="orderDetilTableID"
              :columnsData="detail_columns"
              :customHeight="300"
              rowKey="sku_id"
              class="mt-2"
              :tableData="expressOrder.goods_list"
            ></base-table>
          </template>
          <template v-if="giftList.length > 0">
            <div class="flex justify-between mt-4">
              <span class="text-sm font-bold text-black">赠品</span>
            </div>
            <base-table
              ref="orderDetilTableRef"
              id="orderDetilTableID"
              :columnsData="gift_columns"
              rowKey="sku_id"
              :tableData="giftList"
            ></base-table>
          </template>
          <template v-if="conponList.length > 0">
            <div class="flex justify-between mt-4">
              <span class="text-sm font-bold text-black">赠券</span>
            </div>
            <base-table
              class="shop-table-class mt-2"
              ref="orderDetilTableRef"
              id="orderDetilTableID"
              :columnsData="conpon_columns"
              row-key="id"
              :tableData="conponList"
            ></base-table>
          </template>

        </a-collapse-panel>
      </a-collapse>
    </div>

    <!-- 流水、操作记录、售后 -->
    <div class="flex justify-between items-center">
      <div>
        <a-button class="bottom-btn" type="link" @click="isShowFlow = true">查看流水</a-button>
        <a-button class="bottom-btn" type="link" @click="isShowOptLog = true">查看操作记录</a-button>
        <a-button class="bottom-btn" type="link" @click="isShowPushLog = true">查看收银推送记录</a-button>
        <!-- 售后开关 -->
        <template v-if="!isOtherPlatOrder">
          <template v-if="data.order_status == 30">
            <span v-if="!data.refund_switch">售后开关:未设置</span>
            <span v-if="data.refund_switch==1">售后开关:已打开</span>
            <span v-if="data.refund_switch==2">售后开关:已关闭</span>
            <template v-if="hasAuthAudit">
              <a-button v-if="data.refund_switch==1" 
                  class="bottom-btn" type="link"
                  @click="switchRefund(false)">(点击关闭)</a-button>
              <a-button v-else 
                  class="bottom-btn" type="link"
                  @click="switchRefund(true)">(点击开启)</a-button>
            </template>
          </template>
          <!-- 售后退款 -->
          <template v-if="data.order_status >= 30">
            <a-button v-if="data.order_status == 30 && hasAuthAudit" 
              class="bottom-btn" type="link" @click="isShowRefundApply = true">申请退款</a-button>
            <a-button v-if="data.order_status == 40 && hasAuthAudit" 
              class="bottom-btn" type="link" @click="isShowRefundAudit = true">审核退款</a-button>
            <a-button v-if="data.order_status > 40" 
              class="bottom-btn" type="link" @click="isShowRefundDetail = true">退款详情</a-button>
          </template>
        </template>
        <!-- 订单发票 -->
        <template v-if="data.order_status >= 30">
          <a-button class="bottom-btn" type="link"
            v-if="data.invoice_apply_list && data.invoice_apply_list.length" 
            @click="isShowInvoiceDetail = true">已申请发票</a-button>
          <span v-else>未申请发票</span>
        </template>
      </div>
      <div>
        <a-button class="bottom-btn" type="link" @click="isShowSmsLog = true">短信历史</a-button>
        <a-button v-if="hasAuthAudit" class="bottom-btn" type="link" @click="showSendSms">发短信</a-button>
      </div>
    </div>
      
    
    <!-- 审核 -->
    <template v-if="isCakeAudit || isDrinkAudit">
      <div class="bottom-space"></div>
      <div class="operate-fixed-footer flex justify-center items-center mt-3">
        <a-button v-if="data.audit_status==1" class="mr-7" @click="handlerSave(1)">仅保存</a-button>
        <template>
          <a-button type="primary" @click="handlerSave(2)">保存并完成审核</a-button>
          <span class="ml-2" style="color:#aaa;font-size:12px;">*会推给收银、会触发门店自动打印</span>
        </template>
      </div>
    </template>

    <select-outlets-modal
      v-if="isSelectOutlets"
      @ok="handlerSureOutlets"
      @getSplitGoods="handlerOutletNoGoodsSure"
      :show.sync="isSelectOutlets"
      :orderId="order_no"
      :type="outletOrder.suborder_type"
    />
    <split-order-modal
      v-if="isShowSplitOrder"
      :show.sync="isShowSplitOrder"
      :orderId="order_no"
      :selectKeys="splitGoodsKeys"
      @ok="handlerSplitOrder"
    />
    
    <!-- 售后 -->
    <RefundApply v-if="isShowRefundApply" :show.sync="isShowRefundApply" :data="refundApplyForm" />
    <RefundAudit v-if="isShowRefundAudit" :show.sync="isShowRefundAudit" 
        :id="data.refund_work_sheet_id" :orderNo="order_no" />
    <RefundDetail v-if="isShowRefundDetail" :show.sync="isShowRefundDetail" 
        :id="data.refund_work_sheet_id" :orderNo="order_no" />

    <!-- 发票详情 -->
    <InvoiceDetail
      v-if="isShowInvoiceDetail"
      :show.sync="isShowInvoiceDetail"
      :requestList="data.invoice_apply_list"
    />

    <!-- 物流 -->
    <QueryWindow v-if="isShowQuery" :show.sync="isShowQuery" 
        :requestID="order_no" 
        :hasDelivery="query_delivery" 
        :hasExpress="query_express" />
    <!-- 弹窗：流水、操作记录 -->
    <flow-modal
      v-if="isShowFlow"
      :show.sync="isShowFlow"
      :orderId="order_no" />
    <opt-modal
      v-if="isShowOptLog"
      :show.sync="isShowOptLog"
      :orderId="order_no" />
    <push-modal
      v-if="isShowPushLog"
      :show.sync="isShowPushLog"
      :orderId="order_no" />
      
    <add-conpon-modal :orderId="order_no" v-if="isShowAddConpon" :show.sync="isShowAddConpon" />
  </div>

  <!-- 取消弹窗（包含其他支付） -->
  <cancel-modal v-if="isShowCancel"
       :show.sync="isShowCancel"
       :payInfo="data"
       @ok="handlerCancelSure" />
  <!-- 取消旺店通弹窗（旺店通已发货） -->
  <a-modal v-if="isShowWdtcancel" :visible.sync="isShowWdtcancel"
           title="旺店通已发货，请先去旺店通拦截成功后，再继续操作"
           destroyOnClose
           width="500px"
           okText="确定"
           @ok="handleWdtCancelSure"
           @cancel="handleHideWdtCancel">
      <div>
        <a-checkbox class="ml-6" v-model="isWdtCancelComplete">已在旺店通处理完毕，继续取消订单</a-checkbox>
      </div>
  </a-modal>

  <!-- 支付弹窗 -->
  <pay-modal v-if="showPay" :show.sync="showPay"
       :orderId="order_no"
       :orderType="orderType"
       :orderCityAdcode="data.outlets_city_adcode"
       :totalAmount="Number(data.order_amount)"
       @paystatus="getPayStatus"></pay-modal>

  <!-- 自提/配送 转换 -->
  <transition-type v-if="isShowTransition" :show.sync="isShowTransition"
      :isCashDelivery="data.is_cash_delivery_order==2"
      :outletsOrder="outletOrder"
      :orderId="order_no"
      :freightAmount="data.freight_amount"
      :openTime="openTime"
      @ok="initData"/>

  <!-- 弹窗：短信 -->
  <smslog-modal
    v-if="isShowSmsLog"
    :show.sync="isShowSmsLog"
    :orderId="order_no" />
  <sendsms-modal
    v-if="isShowSendSms"
    :show.sync="isShowSendSms"
    :orderId="order_no"
    :phoneList="smsPhoneList" />
</div>
</template>

<script>
// import Clipboard from 'clipboard';
import { 
  getAllOrderDetail,
  cancelCakeOrder,
  notCakeCancelOrder,
  changeAuditCakeOrder,
  updateCustomerRemarks,
  updatePrivateRemarks,
  updateRefundSwitch,
  tryFinishExpressOrder
} from "@/api/customer"
import { getUserBlackInfo } from "@/api/vip"
import { subCODPayTypeList } from '@/utils/type'
import pageData from "./columns"
import {
  formatBirthdayCandle,
  formatUserOrderStatus,
  formatPdSubOrderStatus,
  formatEpSubOrderStatus,
  formatOrderSource,
  tableWare
} from "@/utils/type"
import { 
  checkAuth,
  checkPage
} from '@/utils/authData.js'
import SelectOutletsModal from "../components/select-outlets-modal.vue"
import QueryWindow from "@/components/order/queryWindow"
import FlowModal from "@/components/order/flow-modal.vue"
import OptModal from "@/components/order/opt-modal.vue"
import PushModal from "@/components/order/push-modal.vue"
import AddConponModal from '../components/add-conpon-modal.vue'
import TimePicker2 from "@/components/order/time-picker2.vue"
import SplitOrderModal from "../components/split-order-modal.vue"
import { getMapPointList, getAdcode, completeAdcode } from "@/utils/tMap"
import BirthdayCandle from '@/components/order/birthday-candle.vue'
import CancelModal from '@/components/order/cancel-modal.vue'
import PayModal from '../order/components/pay-modal.vue'
import PayTip from "@/components/order/pay-tip.vue"
import { searchShopDeliveryArea } from "@/utils/delivery.js"
import { formatOrderGoodsSaleModel } from "@/utils/sale-model.js"


import RefundApply from "../refund/components/refund-apply"
import RefundAudit from "../refund/components/refund-audit"
// import RefundDetail from "../refund/components/refund-detail"
import RefundDetail from "../../trading-manage/refund/components/refund-detail"
import InvoiceDetail from "../../invoice/order-invoice/components/detail"
import TransitionType from './components/transition-type.vue'
import SmslogModal from "@/components/order/smslog-modal.vue"
import SendsmsModal from "@/components/order/sendsms-modal.vue"

export default {
  components: {
    SelectOutletsModal,
    QueryWindow,
    FlowModal,
    OptModal,
    PushModal,
    TimePicker2,
    SplitOrderModal,
    BirthdayCandle,
    AddConponModal,
    CancelModal,
    PayModal,
    PayTip,
    RefundApply,
    RefundAudit,
    RefundDetail,
    InvoiceDetail,
    TransitionType,
    SmslogModal,
    SendsmsModal
  },
  props: {
    orderNo: {
      type: String,
      default: "",
    },
    num: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      formatOrderSource,
      tableWare,
      query_order_no: "",
      query_suborder_no: "",
      order_no: "",

      hasAuthTryExpress: checkPage('/not-finish-express-other'),
      hasAuthAudit: checkAuth('all_order:audit'),
      isCakeAudit: false,
      isDrink: false,
      isDrinkAudit: false,
      // isInProcess: false,
      onlyRead: false,

      ...pageData,

      payData: [],
      giftList: [],
      conponList: [],

      // 订单商品/地址、拆单
      isHaveExpress: false,
      isHaveOutlets: false,

      allOutletGoodsList: [],  // 门店子订单的商品
      leftOutletGoodsList: [], // 门店子订单的商品（过滤了拆单商品后）
      addressList: [],
      addressList2: [],
      addressList3: [],

      showSplitBtn: false,
      splitGoodsList: [],
      splitGoodsKeys: [],
      noStockgoodsList: [],
      
      data: {},
      outletOrder: {},
      expressOrder: null,
      orderType: "",
      orderStatus_orderType: "",

      cakeList: [],
      updateUserInfo: {
        pick_up_delivery_order: {}
      }, // 存放有修改的数据，进行数据对比并且增加红框样式
      // 提交审核的表单
      formState: {
        order_no: "",
        pay_subtype: 0,

        pick_up_outlets_id: "",
        delivery_outlets_id: "",
        order_remarks: "",
        goods_time: [],
        customer_service_remarks: "",
        private_remarks: "",
        // outlets_remarks: "",

        // 拆单动作，提交内容
        express_consignee_name: "",
        express_consignee_phone: "",
        express_consignee_address: "",
        express_consignee_lng: "",
        express_consignee_lat: "",
        express_consignee_adcode: "",
      },
      goods_time_duration: 30,
      
      isShowFlow: false,
      isShowOptLog: false,
      isShowPushLog: false,
      isShowQuery: false,
      query_delivery: false,
      query_express: false,

      subCODPayTypeList: subCODPayTypeList,

      isInDrawer: false,
      isCakeOrder: false,
      
      isShowCancel: false,
      isShowWdtcancel: false,
      isWdtCancelComplete: false,
      cancelOrderRefundData: {},
      
      // 拆单
      isSelectOutlets: false,
      isOuteletsNoGoods: false,
      isUpdateUserInfo: false,
      isShowSplitOrder: false,
      // 自提配送互转
      isShowTransition: false,

      // 售后送券
      isShowAddConpon: false,
      // 售后
      isShowRefundApply: false,
      isShowRefundAudit: false,
      isShowRefundDetail: false,
      refundApplyForm: {},

      isShowInvoiceDetail: false,

      showPay: false,
      payedAmount: 0,
      
      isInBlackList: false,
      blackListRemarks: "",

      isOtherPlatOrder: false,
      isCashierOrder: false,
      isShowSmsLog: false,
      isShowSendSms: false,
      smsPhoneList: [],
      // addrSearchAutoFocus: false,

      openTime: "",
    }
  },
  watch:{
    num(){
      this.initData()
    }
  },
  created() {
    if(this.$route.query.show){ // 如果是新开页
      this.onlyRead = true
    }
    
    if(this.orderNo){ // 如果是抽屉页
      this.order_no = this.orderNo
      this.isInDrawer = true
    }else if(this.$route.query.order_no){ // 如果是新开页
      this.order_no = this.$route.query.order_no
      if(/^s/i.test(this.$route.query.order_no)){
        this.query_suborder_no = this.$route.query.order_no
      }else{
        this.query_order_no = this.$route.query.order_no
      }
    }
    
    // this.formState.order_no = this.order_no
  },
  computed: {
    totalPrice() {
      let price = 0
      this.splitGoodsList.map((item) => {
        price = price + parseFloat(item.order_price)
      })
      return price
    },
    // 判断收货 取货时间是否有改动
    isTimeUpdate() {
      // 如果是自提 则判断取货时间
      if (this.isUpdateUserInfo) {
        if (this.outletOrder.suborder_type === 1) {
          if (this.outletOrder.pick_up_info.pick_up_goods_time_start != this.updateUserInfo.pick_up_delivery_order.pick_up_info.pick_up_goods_time_start || this.outletOrder.pick_up_info.pick_up_goods_time_end != this.updateUserInfo.pick_up_delivery_order.pick_up_info.pick_up_goods_time_end) {
            return true
          } else {
            return false
          }
        } else {
          if (this.outletOrder.delivery_info.delivery_time_start != this.updateUserInfo.pick_up_delivery_order.delivery_info.delivery_time_start || this.outletOrder.delivery_info.delivery_time_end != this.updateUserInfo.pick_up_delivery_order.delivery_info.delivery_time_end) {
            return true
          } else {
            return false
          }
        }
      } else {
        return false
      }

    },
    oldDeliveryInfo() {
      return this.updateUserInfo.pick_up_delivery_order.delivery_info || {}
    },
    oldPickUpInfo() {
      return this.updateUserInfo.pick_up_delivery_order.pick_up_info || {}
    },
    oldCakeAccessoryList() {
      return this.updateUserInfo.pick_up_delivery_order.cake_accessory_list || []
    }
  },
  mounted() {
    this.initData()
  },

  methods: {
    formatBirthdayCandle,
    formatUserOrderStatus,

    async initData() {
      // 重置
      this.splitGoodsList = []

      const { data, code, timestamp } = await getAllOrderDetail({
        order_no: this.query_order_no,
        suborder_no: this.query_suborder_no,
      })
      if(code != 0) return;

      this.isOtherPlatOrder = data.order_source >= 20
      this.isCashierOrder = data.order_source==16

      this.openTime = timestamp
      this.order_no = data.order_no
      this.formState.order_no = data.order_no
      this.formState.private_remarks = data.private_remarks
      this.data = { ...data }

      // vip_card_pay_amount float 会员卡支付金额
      // stored_value_card_pay_amount  float 储值卡支付金额
      // wx_pay_amount float 微信支付金额
      // alipay_amount float 支付宝支付金额
      // cash_delivery_amount  float 货到付款金额
      // other_pay_amount  float 其它支付金额
      // on_account_amount float 挂账金额
      this.payedAmount = Math.round(( Number(data.vip_card_pay_amount) + 
                          Number(data.stored_value_card_pay_amount) + 
                          Number(data.wx_pay_amount) + 
                          Number(data.alipay_amount) + 
                          Number(data.cash_delivery_amount) + 
                          Number(data.other_pay_amount) + 
                          Number(data.on_account_amount) )*100)/100

      if (data.express_order && data.express_order.suborder_no) {
        data.express_order.goods_list.map(gd=>{
          const info = formatOrderGoodsSaleModel(gd.goods_sale_model_info)
          gd.sale_model_desc = info ? info.desc : ""
        })

        let expressOrder = data.express_order
            expressOrder.suborder_statusname = formatEpSubOrderStatus(expressOrder.suborder_status)
        this.expressOrder = expressOrder
        this.orderType = 3
        this.isHaveExpress = true
      }
      if (data.pick_up_delivery_order && data.pick_up_delivery_order.suborder_no) {
        let outletOrder = data.pick_up_delivery_order
            outletOrder.suborder_statusname = formatPdSubOrderStatus(outletOrder.suborder_status)
        this.outletOrder = outletOrder
        this.orderType = outletOrder.suborder_type
        this.isHaveOutlets = true
        this.isDrink = data.pick_up_delivery_order.goods_list.some(gd=>gd.goods_type==4)
        // 好茶：商品属性、合并
        if(this.isDrink){
          const merge_goods_list = []
          outletOrder.goods_list.map(sku=>{
            const merge_sku = merge_goods_list.find(el=>{
              return el.sku_id == sku.sku_id
                    && el.drink_temperature_name == sku.drink_temperature_name
                    && el.drink_sugar_name == sku.drink_sugar_name
                    && el.drink_straw_name == sku.drink_straw_name
            })
            if(merge_sku){
              sku.show_desc = merge_sku.show_desc
              merge_sku.number = merge_sku.number + sku.number
              if(sku.drink_taken_status==2){
                merge_sku.finish_num = merge_sku.finish_num + sku.number
              }
              merge_sku.goods_price = Math.round((Number(merge_sku.goods_price) + Number(sku.goods_price))*100)/100
              merge_sku.order_price = Math.round((Number(merge_sku.order_price) + Number(sku.order_price))*100)/100
            }else{
              const tmp = []
              // if(sku.flavor_name)            tmp.push(sku.flavor_name)
              if(sku.specification_name)     tmp.push(sku.specification_name)
              if(sku.drink_temperature_name) tmp.push(sku.drink_temperature_name)
              if(sku.drink_sugar_name)       tmp.push(sku.drink_sugar_name)
              if(sku.drink_straw_name)       tmp.push(sku.drink_straw_name)
              sku.show_desc = tmp.join("；")
              sku.finish_num = sku.drink_taken_status==2?sku.number:0
              merge_goods_list.push({ ...sku })
            }
          })
          outletOrder.merge_goods_list = merge_goods_list
        }
      }
      // 拆单：配送单已完成&快递未完成，按照快递走
      if(this.isHaveExpress && this.isHaveOutlets){
        if(this.outletOrder.suborder_status == 6){
          if(this.expressOrder.suborder_status > - 1 && this.expressOrder.suborder_status != 6){
            this.orderStatus_orderType = 3
          }
        }
      }

      this.$emit('getDetail', this.data)

      this.giftList = data.event_gift_goods_list || []
      this.conponList = data.event_gift_coupon_list.map((item, i) => {
        item.id = i
        return item
      }) || []

      if(this.isHaveOutlets){
        this.isCakeOrder = this.outletOrder.goods_list.findIndex(sku=>{
          return sku.goods_type==3
        }) > -1
      }
      
      this.payData = [{
        key: "0",
        wx_pay_amount:                data.wx_pay_amount,
        vip_card_pay_amount:          data.vip_card_pay_amount,
        stored_value_card_pay_amount: data.stored_value_card_pay_amount,
        alipay_amount:                data.alipay_amount,
        other_pay_amount:             data.other_pay_amount,
        cash_delivery_amount:         data.cash_delivery_amount,
        on_account_amount:            data.on_account_amount,
      }]

      // 黑名单
      if(this.data.member_id && !this.onlyRead){
        getUserBlackInfo({ member_id: this.data.member_id }).then(res=>{
          if(res.code == 0){
            this.isInBlackList = res.data.blacklist_status==2
            this.blackListRemarks = res.data.blacklist_remarks || ""
          }
        })
      }

      // 售后申请的表单
      const suborder_list = []
      if(this.isHaveOutlets){
        suborder_list.push(this.outletOrder)
      }
      if(this.isHaveExpress){
        suborder_list.push(this.expressOrder)
      }
      this.refundApplyForm = {
        order_no: data.order_no,
        suborder_list: suborder_list,
        order_amount: data.order_amount,
        // goods_amount: data.goods_amount,
        freight_amount: data.freight_amount,
        pack_amount: data.pack_amount,
        order_source: data.order_source,
      }

      // 备注编辑态 & 审核态
      this.formState.customer_service_remarks = data.customer_service_remarks
      this.formState.private_remarks = data.private_remarks

      // this.isInProcess = data.order_status >= 2 && data.order_status < 30
      // 审核态
      if(data.order_status >= 2 && data.order_status < 30){
        if(this.hasAuthAudit && this.isHaveOutlets){
          if(this.isCakeOrder || data.order_source==10){
            this.isCakeAudit = true
            this.initAuditData(data)
          // }else if(this.isDrink){
          //   this.isDrinkAudit = true
          //   this.initAuditData(data)
          }
        }
      }
    },

    initAuditData(data){
      this.formState.pay_subtype = data.pay_subtype
      this.formState.order_remarks = data.order_remarks
      this.formState.customer_service_remarks = data.customer_service_remarks
      this.formState.private_remarks = data.private_remarks
      // this.formState.outlets_remarks = data.outlets_remarks
      
      // 网点订单解析
      if(this.isHaveOutlets){  
        let outletOrder = this.outletOrder
        if (outletOrder.suborder_type == 1) { // 如果是自提单
          const time_start = outletOrder.pick_up_info.pick_up_goods_time_start
          const time_end   = outletOrder.pick_up_info.pick_up_goods_time_end
          let date = time_start.split(" ")[0]
          let time = time_start.split(" ")[1].substr(0, 5) + "-" + time_end.split(" ")[1].substr(0, 5)
          this.goods_time_duration = Math.round((new Date(time_end).getTime()-new Date(time_start).getTime())/60/1000)
          outletOrder.goods_time = [date, time]
          this.formState.goods_time = [date, time]
          this.formState.pick_up_outlets_id = outletOrder.outlets_id
        } else { // 如果是配送单
          const time_start = outletOrder.delivery_info.delivery_time_start
          const time_end   = outletOrder.delivery_info.delivery_time_end
          let date = time_start.split(" ")[0]
          let time = time_start.split(" ")[1].substr(0, 5) + "-" + time_end.split(" ")[1].substr(0, 5)
          this.goods_time_duration = Math.round((new Date(time_end).getTime()-new Date(time_start).getTime())/60/1000)
          outletOrder.goods_time = [date, time]
          this.formState.goods_time = [date, time]

          this.formState.delivery_outlets_id = outletOrder.outlets_id
          outletOrder.delivery_info.consignee_lng = outletOrder.delivery_info.consignee_lng_lat.split(" ")[0]
          outletOrder.delivery_info.consignee_lat = outletOrder.delivery_info.consignee_lng_lat.split(" ")[1]
          
          // 是否可以拆单 --> 拆单按钮是否展示
          let hasNotCake = outletOrder.goods_list.find(el=>{return el.goods_type != 3})
          if(hasNotCake && data.can_split_order == 2){
            this.showSplitBtn = true
          }
        }
        this.outletOrder = outletOrder

        this.allOutletGoodsList  = outletOrder.goods_list
        this.leftOutletGoodsList = outletOrder.goods_list
        this.cakeList = outletOrder.cake_accessory_list
      }
        
      // 快递订单解析
      if(this.isHaveExpress){
        this.expressOrder.express_info.consignee_lng = this.expressOrder.express_info.consignee_lng_lat.split(" ")[0]
        this.expressOrder.express_info.consignee_lat = this.expressOrder.express_info.consignee_lng_lat.split(" ")[1]
      }

      // 订单修改状态 （1-未修改，2-已修改） 如果是2，则赋值修改对比数据
      if (data.order_update_status === 2) {
        this.isUpdateUserInfo = true
        this.updateUserInfo = data.order_update_historical_data
      }
    },

    openUserDetail(){
      const newPage = this.$router.resolve({
        name: "memberDetail",
        query: { member_id: this.data.member_id },
      });
      window.open(newPage.href, "_blank");
    },

    showPaySub(){
      this.$refs.payTypeSelect.$el.click()
    },
    getCODPayType(subpaytype){
      subpaytype = subpaytype || 0
      let item = this.subCODPayTypeList.find(el=>{
        if(subpaytype == el.id) return el
      })
      return item && item.name || "未知"
    },
    // 物流
    handlerQuery(subOrderType) {
      this.query_delivery = subOrderType==2
      this.query_express  = subOrderType==3
      this.isShowQuery = true
    },
    // 修改网点
    handlerSelectOutlets() {
      this.noStockgoodsList = []
      this.isOuteletsNoGoods = false
      this.isSelectOutlets = true
    },
    // 确认选择网点
    handlerSureOutlets(row) {
      if(this.outletOrder.suborder_type==1){
        this.formState.pick_up_outlets_id = row.outlets_id
      }else{
        this.formState.delivery_outlets_id = row.outlets_id
      }
      this.outletOrder.outlets_name = row.outlets_name
    },

    // 如果切换的负责网点没有库存，则对勾选的商品进行拆单赋值
    handlerOutletNoGoodsSure(infos) {
      // 如果是选中拆单
      if (infos.isSplit) {
        const arr = []
        infos.goods.map((el) => {
          const item = this.allOutletGoodsList.find(
            (i) => i.order_goods_id === el.order_goods_id
          )
          arr.push(item)
        })
        this.handlerSplitOrder(arr)
      }

      if(this.outletOrder.suborder_type==1){
        this.formState.pick_up_outlets_id = infos.outlets.outlets_id
      }else{
        this.formState.delivery_outlets_id = infos.outlets.outlets_id
      }
      this.outletOrder.outlets_name = infos.outlets.outlets_name
    },

    // 点击拆单按钮事件，
    handlerSplit() {
      // 查询是否已经选过一波数据，如果已选一波给自动赋值
      this.splitGoodsKeys = this.splitGoodsList.map((el) => el.order_goods_id)
      this.isShowSplitOrder = true
    },

    // 确认拆单
    async handlerSplitOrder(rows) {
      this.splitGoodsList = rows
      const goodsIds = rows.map(el=>el.order_goods_id)

      this.leftOutletGoodsList = this.allOutletGoodsList.filter(el => !goodsIds.includes(el.order_goods_id))

      this.formState.express_consignee_name     = this.outletOrder.delivery_info.consignee_name
      this.formState.express_consignee_phone    = this.outletOrder.delivery_info.consignee_phone
      this.formState.express_consignee_address  = this.outletOrder.delivery_info.consignee_address
      this.formState.express_consignee_lng      = this.outletOrder.delivery_info.consignee_lng
      this.formState.express_consignee_lat      = this.outletOrder.delivery_info.consignee_lat
      if (this.outletOrder.delivery_info.consignee_adcode) {
        this.formState.express_consignee_adcode = this.outletOrder.delivery_info.consignee_adcode
      } else {
        // test 这个else逻辑没啥用吧
        const adcodesRes = await getAdcode(
          this.formState.express_consignee_lat,
          this.formState.express_consignee_lng
        )
        if (adcodesRes.status === 0 && adcodesRes.result.address_reference.town){
          // this.formState.express_consignee_adcode = adcodesRes.result.address_reference.town.id
          let adcode = adcodesRes.result.ad_info.adcode
          if (adcodesRes.result.address_reference && adcodesRes.result.address_reference.town) {
            adcode = adcodesRes.result.address_reference.town.id
          }
          this.formState.express_consignee_adcode = completeAdcode(adcode)
        }
      }
    },

    // 保存
    async handlerSave(update_audit) {
      let params = JSON.parse(JSON.stringify(this.formState))
      if(this.data.pay_type != 10){ // 不是货到付款
        params.pay_subtype = ""
      }
      // 1 门店子订单
      if (this.outletOrder.suborder_type === 2) { // 配送单
        params.delivery_time_start =
          this.formState.goods_time[0] + " " +
          this.formState.goods_time[1].split("-")[0]
        params.delivery_time_end =
          this.formState.goods_time[0] + " " +
          this.formState.goods_time[1].split("-")[1]
        params.delivery_consignee_name    = this.outletOrder.delivery_info.consignee_name
        params.delivery_consignee_phone   = this.outletOrder.delivery_info.consignee_phone
        // params.delivery_consignee_address = this.outletOrder.delivery_info.consignee_address
        params.delivery_consignee_address = this.outletOrder.delivery_info.consignee_address + (this.outletOrder.delivery_info.consignee_address_detail||"")

        // 经纬度
        params.delivery_consignee_adcode  = this.outletOrder.delivery_info.consignee_adcode
        params.delivery_consignee_lng     = this.outletOrder.delivery_info.consignee_lng
        params.delivery_consignee_lat     = this.outletOrder.delivery_info.consignee_lat
        if (!this.outletOrder.delivery_info.consignee_adcode) {
          const adcodesRes = await getAdcode(
            params.delivery_consignee_lat,
            params.delivery_consignee_lng
          )
          if (adcodesRes.status === 0 && adcodesRes.result.address_reference.town){
            // params.delivery_consignee_adcode = adcodesRes.result.address_reference.town.id
            let adcode = adcodesRes.result.ad_info.adcode
            if (adcodesRes.result.address_reference && adcodesRes.result.address_reference.town) {
              adcode = adcodesRes.result.address_reference.town.id
            }
            this.formState.delivery_consignee_adcode = completeAdcode(adcode)
          }
        }

        if(!params.delivery_consignee_address){
          this.$message.warning('收货地址不能为空')
          return
        }
        if(!params.delivery_consignee_phone){
          this.$message.warning('手机号不能为空')
          return
        }
      } else { // 自提单
        params.pick_up_goods_time_start =
          this.formState.goods_time[0] + " " +
          this.formState.goods_time[1].split("-")[0]
        params.pick_up_goods_time_end =
          this.formState.goods_time[0] + " " +
          this.formState.goods_time[1].split("-")[1]
        params.pick_up_recipient_name  = this.outletOrder.pick_up_info.recipient_name
        params.pick_up_recipient_phone = this.outletOrder.pick_up_info.recipient_phone
      }
      params.cake_accessory_data = JSON.stringify(this.outletOrder.cake_accessory_list)

      // 2 快递子订单
      if(this.expressOrder){
        params.express_consignee_name     = this.expressOrder.express_info.consignee_name
        params.express_consignee_phone    = this.expressOrder.express_info.consignee_phone
        params.express_consignee_address  = this.expressOrder.express_info.consignee_address + (this.expressOrder.express_info.consignee_address_detail||"")
        params.express_consignee_lat    = this.expressOrder.express_info.consignee_lat
        params.express_consignee_lng    = this.expressOrder.express_info.consignee_lng
      }
      
      // 3 拆单数据
      if (this.splitGoodsList.length > 0) {
        params.split_order_goods_data = JSON.stringify(
          this.splitGoodsList.map((o) => {
            return { order_goods_id: o.order_goods_id }
          })
        )
        if(!params.express_consignee_address){
          this.$message.warning('收货地址不能为空')
          return
        }
        if(!params.express_consignee_phone){
          this.$message.warning('手机号不能为空')
          return
        }
      }
      
      params.update_audit = update_audit
      params.open_time = this.openTime
      changeAuditCakeOrder(params).then((res) => {
        if (res.code === 0) {
          this.$message.success("操作成功")
          this.$emit("ok", res)
          if(!this.isInDrawer){
            this.initData()
          }
        }
      })
    },

    // // 地址输入
    // handleAddrBlur(e){
    //   console.log("handleAddrBlur", e)
    //   this.addrSearchAutoFocus = false
    // },
    // addrSearchCopy(){
    //   var clipboard = new Clipboard('#addr-search');
    //   console.log("log", clipboard)

    //   clipboard.on('success', ()=>{
    //     console.log("addrSearchCopy success")
    //     clipboard.destroy()
    //     this.$message.success("已复制")
    //   })
    //   clipboard.on('error', ()=>{
    //     console.log("addrSearchCopy error")
    //     this.$message.info("浏览器不支持")
    //     clipboard.destroy()
    //   })
    // },
    // addrSearchFocus(){
    //   this.addrSearchAutoFocus = true
    // },
    async handleSearch(value) {
      // 根据经纬度反查Adcode
      getMapPointList(value).then((res) => {
        if (res.status == 0) {
          this.addressList = res.data.map(item=>{
            item.region = item.province+item.city+item.district
            return item
          })
        }
      })
    },

    async handleChange(value) {
      const item = this.addressList.find((item) => item.id === value)
      this.outletOrder.delivery_info.consignee_address = (item.address || '') + (item.title || '')
      this.outletOrder.delivery_info.consignee_lat = item.location.lat
      this.outletOrder.delivery_info.consignee_lng = item.location.lng
      // 根据经纬度反查Adcode
      const adcodesRes = await getAdcode(item.location.lat, item.location.lng)

      if (adcodesRes && adcodesRes.status === 0) {
        // this.formState.consignee_adcode = Number(adcodesRes.result.address_reference.town.id + '000') || ''
        let adcode = adcodesRes.result.ad_info.adcode
        if (adcodesRes.result.address_reference && adcodesRes.result.address_reference.town) {
          adcode = adcodesRes.result.address_reference.town.id
        }
        this.formState.consignee_adcode = completeAdcode(adcode)
      } else {
        this.formState.consignee_adcode = ''
      }

      // 修改收货地址 --> 重新计算配送网点
      if(this.formState.consignee_adcode){
        // this.autoFigureDlvrOutlets()
        let area = await searchShopDeliveryArea({
          lat: this.outletOrder.delivery_info.consignee_lat,
          lng: this.outletOrder.delivery_info.consignee_lng
        }, this.formState.consignee_adcode)

        if (area) {
          if(area.outlets_id != this.formState.delivery_outlets_id){
            const _this = this
            this.$confirm({
              title: '',
              content: '已自动计算出更合适的配送网点“'+area.outlets_name+'”，是否切换？',
              okText: "切换新网点",
              cancelText: "取消",
              onOk () {
                _this.formState.delivery_outlets_id = area.outlets_id
                _this.outletOrder.outlets_name = area.outlets_name
              },
            });
            // test 应该提示下 运费对比
            // area.freight、 this.data.freight_amount
          }
        } else {
          this.$message.warning("地址超出配送范围");
        }
      }

    },

    // 快递单地址切换的操作
    async handleSearch2(value) {
      // 根据经纬度反查Adcode
      getMapPointList(value).then((res) => {
        if (res.status == 0) {
          this.addressList2 = res.data.map(item=>{
            item.region = item.province+item.city+item.district
            return item
          })
        }
      })
    },

    async handleChange2(value) {
      const item = this.addressList2.find((item) => item.id === value)
      this.expressOrder.express_info.consignee_address = (item.address || '') + (item.title || '')
      this.expressOrder.express_info.consignee_lat = item.location.lat
      this.expressOrder.express_info.consignee_lng = item.location.lng
      // 根据经纬度反查Adcode
      const adcodesRes = await getAdcode(item.location.lat, item.location.lng)
      if (adcodesRes && adcodesRes.status === 0) {
        // this.formState.express_consignee_adcode = Number(adcodesRes.result.address_reference.town.id + '000') || ''
        let adcode = adcodesRes.result.ad_info.adcode
        if (adcodesRes.result.address_reference && adcodesRes.result.address_reference.town) {
          adcode = adcodesRes.result.address_reference.town.id
        }
        this.formState.express_consignee_adcode = completeAdcode(adcode)
      } else {
        this.formState.express_consignee_adcode = ''
      }
    },


    // 快递单地址切换的操作
    async handleSearch3(value) {
      // 根据经纬度反查Adcode
      getMapPointList(value).then((res) => {
        if (res.status == 0) {
          this.addressList3 = res.data.map(item=>{
            item.region = item.province+item.city+item.district
            return item
          })
        }
      })
    },

    async handleChange3(value) {
      const item = this.addressList3.find((item) => item.id === value)
      this.formState.express_consignee_address = (item.address || '') + (item.title || '')
      this.formState.express_consignee_lat = item.location.lat
      this.formState.express_consignee_lng = item.location.lng
      // 根据经纬度反查Adcode
      const adcodesRes = await getAdcode(item.location.lat, item.location.lng)

      if (adcodesRes && adcodesRes.status === 0) {
        // this.formState.express_consignee_adcode = Number(adcodesRes.result.address_reference.town.id + '000') || ''
        let adcode = adcodesRes.result.ad_info.adcode
        if (adcodesRes.result.address_reference && adcodesRes.result.address_reference.town) {
          adcode = adcodesRes.result.address_reference.town.id
        }
        this.formState.express_consignee_adcode = completeAdcode(adcode)
      } else {
        this.formState.express_consignee_adcode = ''
      }
    },


    // 传入key,value和序列，去修改数据作对比
    hasCakeUpdate(key, value, index) {
      if (this.oldCakeAccessoryList.length > 0) {
        return value != this.oldCakeAccessoryList[index][key]
      } else {
        return false
      }
    },

    hasDataUpdate(key, value) {
      if (this.isUpdateUserInfo) {
        if (key.includes('.')) {
          if (this.outletOrder.suborder_type === 1) {
            return (this.oldPickUpInfo[key.split('.')[1]] != value)
          } else {
            return (this.oldDeliveryInfo[key.split('.')[1]] != value)
          }
        }
        return (this.updateUserInfo[key] !== value)
      }
      return false
    },
    // 取消订单
    handlerCancel(){
      if(this.checkNotOtherPlatOrder()){
        // 货到付款 & 已开始支付：不能取消
        if(this.data.is_cash_delivery == 2 && this.payedAmount > 0){
          this.$message.info("货到付款订单，已开始支付、不能取消订单")
        }else{
          this.isShowCancel = true
        }
      }
    },
    handlerCancelSure(refundData){
      let params = {
        ...refundData,
        order_no: this.data.order_no
      }
      let cancelFun = this.isCakeOrder ? cancelCakeOrder : notCakeCancelOrder
      const _this = this
      cancelFun(params).then(res => {
        if (res.code === 0) {
          if(res.data.warn){ // 旺店通已审核未发货
            _this.isShowCancel = false
            _this.$emit('order-canceled')
            if(!_this.isInDrawer){
              _this.initData()
            }
            this.$info({
              title: '已取消。但还需要去旺店通操作：',
              content: res.data.warn,
            })
          }else{
            _this.isShowCancel = false
            _this.$message.success("已取消")
            _this.$emit('order-canceled')
            if(!_this.isInDrawer){
              _this.initData()
            }
          } 
        }else if(res.code == 501){  // 旺店通已发货
          _this.cancelOrderRefundData = refundData
          _this.isShowWdtcancel = true
        }
      })
    },
    handleWdtCancelSure(){
      if(!this.isWdtCancelComplete) return

      let params = {
        ...this.cancelOrderRefundData,
        order_no: this.data.order_no,
        wdt_cancel_status: 2
      }
      let cancelFun = this.isCakeOrder ? cancelCakeOrder : notCakeCancelOrder
      const _this = this
      cancelFun(params).then(res => {
        if (res.code === 0) {
          _this.isShowWdtcancel = false
          _this.isShowCancel = false
          _this.$message.success("已取消")
          _this.$emit('order-canceled')
          if(!_this.isInDrawer){
            _this.initData()
          }
        }
      })
    },
    handleHideWdtCancel(){
      this.isShowWdtcancel = false
      this.isWdtCancelComplete = false
    },

    getPayStatus(){
      this.initData()
      this.showPay = false
    },

    async savePrivateRemarks(){
      updatePrivateRemarks({
        open_time: this.openTime,
        order_no: this.data.order_no,
        private_remarks: this.formState.private_remarks
      }).then(res => {
        if (res.code === 0) {
          this.$message.success("已修改")
          this.initData()
        }
      })
    },
    async saveCustomerRemarks(){
      updateCustomerRemarks({
        open_time: this.openTime,
        order_no: this.data.order_no,
        customer_service_remarks: this.formState.customer_service_remarks
      }).then(res => {
        if (res.code === 0) {
          this.$message.success("已修改")
          this.initData()
        }
      })
    },

    async handlerTryFinish(suborder_no){
      const { code } = await tryFinishExpressOrder({
        suborder_no: suborder_no,
        verify_wdt: 2, // 验证旺店通(1-否，2-是)
      })
      if (code == 0) {
        this.$message.success("刷新成功")
        this.initData()
      }
    },

    switchRefund(bool){
      const refund_switch = bool?1:2
      updateRefundSwitch({
        open_time: this.openTime,
        order_no: this.data.order_no,
        refund_switch: refund_switch
      }).then(res => {
        if (res.code === 0) {
          this.data.refund_switch = refund_switch
          this.$message.success("已修改")
        }
      })
    },

    getCakeSkuInfo(order_goods_id){
      let sku = this.outletOrder.goods_list.find(el=>{
        return el.order_goods_id == order_goods_id
      })
      return sku || {}
    },

    checkNotOtherPlatOrder(){
      if(this.isOtherPlatOrder){
        this.$message.info("第三方订单到母订单操作")
        return false
      }
      if(this.isCashierOrder){
        this.$message.info("需要在收银软件操作")
        return false
      }
      
      return true
    },
    openOtherPlatOrder(){
      const newPage = this.$router.resolve({
        name: "otherPlatOrderDetail",
        query: { other_order_id: this.data.other_order_id },
      });
      window.open(newPage.href, "_blank");
    },

    showTransition(){
      if(this.isOtherPlatOrder){
        this.$message.info("第三方订单到母订单操作")
        return false
      }
      // debug 自提货到付款--自提配送互转：暂时限制
      if(this.data.is_cash_delivery_order==2){
        this.$message.info("货到付款订单 不支持转换")
        return false
      }
      // debug 还有啥？

      this.isShowTransition = true
    },


    showSendSms(){
      let phoneList = []
      if(this.outletOrder){
        if(this.outletOrder.suborder_type == 1){
          phoneList.push({
            type: "取货人",
            name: this.outletOrder.pick_up_info.recipient_name,
            phone: this.outletOrder.pick_up_info.recipient_phone,
          })
        }
        if(this.outletOrder.suborder_type == 2){
          phoneList.push({
            type: "收货人",
            name: this.outletOrder.delivery_info.consignee_name,
            phone: this.outletOrder.delivery_info.consignee_phone,
          })
          if(this.outletOrder.delivery_info.contacts_phone){
            phoneList.push({
              type: "联系人",
              name: this.outletOrder.delivery_info.contacts_name,
              phone: this.outletOrder.delivery_info.contacts_phone,
            })
          }
        }
      }
      if(this.data.place_order_phone){
        if(this.data.order_source == 10 || this.data.order_source == 15){
          phoneList.push({
            type: "下单人",
            name: this.data.place_order_name,
            phone: this.data.place_order_phone,
          })
        }else{
          phoneList.push({
            type: "下单用户",
            name: this.data.place_order_name,
            phone: this.data.place_order_phone,
          })
        }
      }
      this.smsPhoneList = phoneList
      this.isShowSendSms = true
    },


    formatTime(time){
      if(/\d{2}:\d{2}:\d{2}/.test(time)){
        return time.slice(0, -3)
      }
      return time
    }

  },
}
</script>

<style lang="less">
.has-update {
  background-color: #fff;
  border-color: #f5222d;
  .ant-select-selection {
    border-color: #f5222d;
  }
  .ant-cascader-input {
    border-color: #f5222d;
  }
  input{
    border-color: #f5222d;
  }
}
</style>
<style lang="less" scoped>
.order-detail-container {
  // margin: 20px;
  padding: 20px 20px 50px 20px;
  background: #ffffff;
}
.shop-table-class {
  // margin-left: -10px;
}
.order-detail-title {
  width: 70px;
  text-align: right;
  display: inline-block;
}
.operate-fixed-footer{
  border-top: 1px solid #eee;
  z-index: 9;
}
.addr-region{
  margin-left: 10px;
  color: #ccc;
}
.thingtohide {
  animation: cssAnimation 0s ease-in 2s forwards;
  animation-fill-mode: forwards;
  transition: all 0.2s ease-in-out ;
}

.btn-link{
  cursor: pointer;
}

.bottom-btn{
  padding: 0;
  margin-right: 20px;
}

@keyframes cssAnimation {
  to {
    width: 0;
    height: 0;
    opacity: 0;
    overflow: hidden;
  }
}
</style>
