
// 订单商品：格式化成售卖模式信息
export function formatOrderGoodsSaleModel(str_info){
	if(!str_info) return null

	const tmp = str_info.split(",")
	
	if(tmp[0] == 2){
	  if(tmp[1] && tmp[2]){
	  	const start = tmp[1].substr(4,4)
	  	const end 	= tmp[2].substr(4,4)
	  	return {
	  		sale_model: 2,
	    	mode: "all",
	    	start: start,
	    	end: end,
	    	desc: "["+start+"~"+end+"发货]"
	    }
	  }else if(tmp[2]){
	  	const end 	= tmp[2].substr(4,4)
	  	return {
	  		sale_model: 2,
	    	mode: "end",
	    	end: end,
	    	desc: "["+end+"前发货]"
	    }
	  }else if(tmp[1]){
	  	const start = tmp[1].substr(4,4)
	  	return {
	  		sale_model: 2,
	    	mode: "start",
	    	start: start,
	    	desc: "["+start+"后发货]"
	    }
	  }
	}

	return null
}

